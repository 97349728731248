/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IAppIdentityUser {
  id?: string | null;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  passwordHash?: string | null;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  /** @format date-time */
  lockoutEnd?: string | Date | null;
  lockoutEnabled?: boolean;
  /** @format int32 */
  accessFailedCount?: number;
  refreshToken?: string | null;
  /** @format date-time */
  refreshTokenExpiryDate?: string | Date | null;
  userProfileFamily?: IUserFamilyProfile;
  userProfileCrm?: IUserCrmProfile;
  userProfileTenant?: IUserTenantProfile;
  botUserProfile?: IBotUserProfile;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
}

export interface IAppLog {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appLogTypeID?: string | null;
  appLogSourceID?: string | null;
  reflectionMetaData?: string | null;
  message?: string | null;
  messageDetails?: string | null;
  appUserID?: string | null;
  appLogType?: IAppLogType;
  appLogSource?: IAppLogSource;
}

export interface IAppLogDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IAppLog[];
}

export interface IAppLogSource {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appLogs?: IAppLog[] | null;
}

export interface IAppLogSourceDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IAppLogSource[];
}

export interface IAppLogType {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appLogs?: IAppLog[] | null;
}

export interface IAppLogTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IAppLogType[];
}

export interface IBodyPart {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  bodyPartContents?: IBodyPartContent[] | null;
  caseBodyParts?: ICaseBodyPart[] | null;
}

export interface IBodyPartContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  bodyPartID?: string | null;
  bodyPart?: IBodyPart;
}

export interface IBodyPartContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBodyPartContent[];
}

export interface IBodyPartDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBodyPart[];
}

export interface IBotCase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  caseID?: string | null;
  botUserProfile?: IBotUserProfile;
  case?: ICase;
  botCaseBrands?: IBotCaseBrand[] | null;
  botCaseTenantProducts?: IBotCaseTenantProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
}

export interface IBotCaseBrand {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botCaseID?: string | null;
  productBrandID?: string | null;
  /** @format int32 */
  maxPromotadProducts?: number;
  /** @format date-time */
  expiredDate?: string | Date;
  /** @format double */
  defaultPriceForView?: number;
  /** @format double */
  defaultPriceForClick?: number;
  /** @format double */
  defaultPriceForCart?: number;
  botCase?: IBotCase;
}

export interface IBotCaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotCase[];
}

export interface IBotCaseTenantProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  botCaseID?: string | null;
  tenantProductID?: string | null;
  /** @format double */
  priceForView?: number;
  /** @format double */
  priceForClick?: number;
  /** @format double */
  priceForCart?: number;
  /** @format int32 */
  positionIndex?: number;
  botCase?: IBotCase;
  tenantProduct?: ITenantProduct;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
}

export interface IBotDomain {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appIdentityUserID?: string | null;
  botUserProfile?: IBotUserProfile;
}

export interface IBotSession {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  userID?: string | null;
  sessionID?: string | null;
  tenantID?: string | null;
  botUserProfileID?: string | null;
  familyUserProfileID?: string | null;
  genderID?: string | null;
  languageID?: string | null;
  familyMemberID?: string | null;
  caseID?: string | null;
  /** @format double */
  age?: number | null;
  /** @format date-time */
  dateOfBirth?: string | Date | null;
  eventType?: string | null;
  url?: string | null;
  location?: string | null;
  longitude?: string | null;
  latitude?: string | null;
  deviceClientType?: string | null;
  deviceClientName?: string | null;
  deviceClientVersion?: string | null;
  deviceClientEngine?: string | null;
  deviceClientEngineVersion?: string | null;
  deviceOSName?: string | null;
  deviceOSVersion?: string | null;
  deviceOSPlatform?: string | null;
  deviceType?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  flowType?: string | null;
  flowTypeDrugCurrentStep?: string | null;
  flowTypeDrugSearchByProductID?: string | null;
  flowTypeDrugSearchByProductMedicationID?: string | null;
  flowTypeDrugDisplayAllCaseProducts?: boolean | null;
  currentBotSessionStepID?: string | null;
  lastStep?: string | null;
  referStopID?: string | null;
  referMedicalConditionID?: string | null;
  referRedFlagID?: string | null;
  referRedFlagPageID?: string | null;
  referRedFlagAnswer?: string | null;
  tenant?: ITenant;
  case?: ICase;
  gender?: IGender;
  language?: ILanguage;
  userFamilyProfile?: IUserFamilyProfile;
  familyMember?: IFamilyMember;
  botUserProfile?: IBotUserProfile;
  botSessionStep?: IBotSessionStep;
  botSessionLogs?: IBotSessionLog[] | null;
  botSessionDosageForms?: IBotSessionDosageForm[] | null;
  botSessionMedicalConditions?: IBotSessionMedicalCondition[] | null;
  botSessionSensitivities?: IBotSessionSensitivity[] | null;
  botSessionProducts?: IBotSessionProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
  botSessionDietaryPreferences?: IBotSessionDietaryPreference[] | null;
}

export interface IBotSessionDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  dietaryPreferenceID?: string | null;
  chosen?: boolean;
  dietaryPreference?: IDietaryPreference;
  botSession?: IBotSession;
}

export interface IBotSessionDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  dosageFormID?: string | null;
  chosen?: boolean;
  dosageForm?: IDosageForm;
  botSession?: IBotSession;
}

export interface IBotSessionLog {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  botSessionLogSource?: IBotSessionLogSource;
  sessionID?: string | null;
  /** @format date-time */
  eventDate?: string | Date;
  /** @format int32 */
  orderIndex?: number;
  flowType?: string | null;
  caseID?: string | null;
  casePageID?: string | null;
  userActionType?: string | null;
  userActionKey?: string | null;
  userActionValue?: string | null;
  userActionEntityID?: string | null;
  botSession?: IBotSession;
}

export enum IBotSessionLogSource {
  Bot = 'Bot',
  Portal = 'Portal',
  Platform = 'Platform',
}

export interface IBotSessionMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  medicalConditionID?: string | null;
  medicalCondition?: IMedicalCondition;
  botSession?: IBotSession;
}

export interface IBotSessionProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  tenantProductID?: string | null;
  /** @format int32 */
  score?: number | null;
  isFavorite?: boolean;
  /** @format int32 */
  positionIndex?: number;
  isPromoted?: boolean;
  botSession?: IBotSession;
  tenantProduct?: ITenantProduct;
}

export interface IBotSessionSensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  sensitivityID?: string | null;
  sensitivity?: ISensitivity;
  botSession?: IBotSession;
}

export interface IBotSessionStep {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  sessionStep?: ISessionStep;
  /** @format int32 */
  flowCompletePercentage?: number;
  isSessionComplete?: boolean;
  botSessions?: IBotSession[] | null;
}

export interface IBotSessionTenantProductAction {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  botSessionID?: string | null;
  botCaseID?: string | null;
  tenantProductID?: string | null;
  /** @format date-time */
  entryDate?: string | Date;
  actionType?: string | null;
  /** @format double */
  priceForAction?: number;
  /** @format int32 */
  positionIndex?: number;
  botSession?: IBotSession;
  botCase?: IBotCase;
  tenantProduct?: ITenantProduct;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
}

export interface IBotTheme {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  description?: string | null;
  textPrimary?: string | null;
  textSecondary?: string | null;
  warningColor?: string | null;
  warningContrastColor?: string | null;
  errorColor?: string | null;
  errorContrastColor?: string | null;
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
  primaryGradientColor?: string | null;
  secondaryColor?: string | null;
  secondaryContrastColor?: string | null;
  secondaryGradientColor?: string | null;
  bgPrimary?: string | null;
  bgSecondary?: string | null;
  botUserProfiles?: IBotUserProfile[] | null;
}

export interface IBotThemeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotTheme[];
}

export interface IBotUserProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  tenantID?: string | null;
  genderID?: string | null;
  botThemeID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  propertyCode?: string | null;
  secret?: string | null;
  icon?: string | null;
  iconPosition?: IIconPosition;
  iconPositionVerticalMargin?: string | null;
  iconPositionHorizontalMargin?: string | null;
  currency?: string | null;
  filterMedicalConditionsTitleLabel?: string | null;
  filterMedicalConditionsSubTitleLabel?: string | null;
  filterDietaryPreferencesTitleLabel?: string | null;
  filterDietaryPreferencesSubTitleLabel?: string | null;
  /** @format int32 */
  botZIndex?: number;
  applyAddToCart?: boolean;
  addToCartCode?: string | null;
  onboardUserInBot?: boolean;
  displayPortalBanner?: boolean;
  displayCaseCategories?: boolean;
  displayAge?: boolean;
  promotedProductBrandID?: string | null;
  trackLog?: boolean;
  internal?: boolean;
  universal?: boolean;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  displayGenderOther?: boolean;
  displayRedFlagConfirm?: boolean;
  displayRecommendationBasket?: boolean;
  displaySearch?: boolean;
  displayLanguageSelector?: boolean;
  displayUnitPrice?: boolean;
  displayProductsInfoHeader?: boolean;
  displayProductMoreInfo?: boolean;
  displayFilterByActiveIngredients?: boolean;
  displayFilterByDosageForms?: boolean;
  appIdentityUser?: IAppIdentityUser;
  gender?: IGender;
  tenant?: ITenant;
  language?: ILanguage;
  botTheme?: IBotTheme;
  promotedProductBrand?: IProductBrand;
  botDomains?: IBotDomain[] | null;
  botCases?: IBotCase[] | null;
  botWelcomeMessages?: IBotWelcomeMessage[] | null;
  botSessions?: IBotSession[] | null;
}

export interface IBotWelcomeMessage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  message?: string | null;
  /** @format int32 */
  dialogMaxWidth?: number;
  welcomeTitle?: string | null;
  welcomeSubTitle?: string | null;
  welcomeMessage?: string | null;
  welcomeBanner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditions?: string | null;
  privacyPolicy?: string | null;
  termsAndConditionsHtmlContent?: string | null;
  privacyPolicyHtmlContent?: string | null;
  termsAndPrivacyLabel?: string | null;
  helpMeLabel?: string | null;
  productSubTitleLabel?: string | null;
  language?: ILanguage;
  botUserProfile?: IBotUserProfile;
}

export enum ICPType {
  RedFlag = 'RedFlag',
  DosageForm = 'DosageForm',
  Symptom = 'Symptom',
  Sensitivity = 'Sensitivity',
  Message = 'Message',
  MedicalCondition = 'MedicalCondition',
  Decision = 'Decision',
  Start = 'Start',
  Stop = 'Stop',
  End = 'End',
  ComputeMedicalCondition = 'ComputeMedicalCondition',
  MedicalConditionGroup = 'MedicalConditionGroup',
  DietaryPreference = 'DietaryPreference',
}

export interface ICase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseCategoryID?: string | null;
  genderID?: string | null;
  countryID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  catalogName?: string | null;
  versionHash?: string | null;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  country?: ICountry;
  gender?: IGender;
  caseCategory?: ICaseCategory;
  caseContents?: ICaseContent[] | null;
  casePages?: ICasePage[] | null;
  caseTags?: ICaseTag[] | null;
  caseBodyParts?: ICaseBodyPart[] | null;
  caseHealthCareTips?: ICaseHealthCareTip[] | null;
  caseMedications?: ICaseMedication[] | null;
  productCases?: IProductCase[] | null;
  botCases?: IBotCase[] | null;
  productsTailorFitCases?: IProductsTailorFitCase[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  redisParams?: IRedisParam[] | null;
}

export interface ICaseBodyPart {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  bodyPartID?: string | null;
  case?: ICase;
  bodyPart?: IBodyPart;
}

export interface ICaseBodyPartDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseBodyPart[];
}

export interface ICaseCategory {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  caseCategoryContents?: ICaseCategoryContent[] | null;
  cases?: ICase[] | null;
}

export interface ICaseCategoryContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseCategoryID?: string | null;
  caseCategory?: ICaseCategory;
}

export interface ICaseCategoryContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseCategoryContent[];
}

export interface ICaseCategoryDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseCategory[];
}

export interface ICaseComputeMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  displayPrintIcon?: boolean;
  casePage?: ICasePage;
}

export interface ICaseComputeMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseComputeMedicalCondition[];
}

export interface ICaseContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseID?: string | null;
  tips?: string | null;
  info?: string | null;
  case?: ICase;
}

export interface ICaseContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseContent[];
}

export interface ICaseDecision {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  /** @format int32 */
  nextPageNumber?: number;
  catalogName?: string | null;
  nextCasePageID?: string | null;
  /** @format int32 */
  fromArrowPosition?: number | null;
  /** @format int32 */
  toArrowPosition?: number | null;
  casePage?: ICasePage;
  caseDecisionContents?: ICaseDecisionContent[] | null;
}

export interface ICaseDecisionContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseDecisionID?: string | null;
  caseDecision?: ICaseDecision;
}

export interface ICaseDecisionContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseDecisionContent[];
}

export interface ICaseDecisionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseDecision[];
}

export interface ICaseDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  dietaryPreferenceID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  casePage?: ICasePage;
  dietaryPreference?: IDietaryPreference;
}

export interface ICaseDietaryPreferenceDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseDietaryPreference[];
}

export interface ICaseDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  dosageFormID?: string | null;
  casePage?: ICasePage;
  dosageForm?: IDosageForm;
}

export interface ICaseDosageFormDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseDosageForm[];
}

export interface ICaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICase[];
}

export interface ICaseHealthCareTip {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  healthCareTipID?: string | null;
  case?: ICase;
  healthCareTip?: IHealthCareTip;
}

export interface ICaseHealthCareTipDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseHealthCareTip[];
}

export interface ICaseMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  medicalConditionID?: string | null;
  caseMedicalConditionGroupID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  casePage?: ICasePage;
  medicalCondition?: IMedicalCondition;
  caseMedicalConditionGroup?: ICaseMedicalConditionGroup;
}

export interface ICaseMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedicalCondition[];
}

export interface ICaseMedicalConditionGroup {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  /** @format int32 */
  pageNumber?: number;
  catalogName?: string | null;
  casePage?: ICasePage;
  caseMedicalConditionGroupContents?: ICaseMedicalConditionGroupContent[] | null;
  caseMedicalConditions?: ICaseMedicalCondition[] | null;
}

export interface ICaseMedicalConditionGroupContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseMedicalConditionGroupID?: string | null;
  caseMedicalConditionGroup?: ICaseMedicalConditionGroup;
}

export interface ICaseMedicalConditionGroupContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedicalConditionGroupContent[];
}

export interface ICaseMedicalConditionGroupDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedicalConditionGroup[];
}

export interface ICaseMedication {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  medicationID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  case?: ICase;
  medication?: IMedication;
}

export interface ICaseMedicationDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedication[];
}

export interface ICasePage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  genderID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  casePageTypeID?: string | null;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  nextPageNumber?: number | null;
  catalogName?: string | null;
  /** @format int32 */
  delay?: number;
  autoSkip?: boolean;
  required?: boolean;
  /** @format double */
  x?: number;
  /** @format double */
  y?: number;
  nextCasePageID?: string | null;
  /** @format int32 */
  fromArrowPosition?: number | null;
  /** @format int32 */
  toArrowPosition?: number | null;
  case?: ICase;
  casePageType?: ICasePageType;
  gender?: IGender;
  redFlagPages?: IRedFlagPage[] | null;
  dosageForms?: IDosageForm[] | null;
  caseDecisions?: ICaseDecision[] | null;
  caseSensitivities?: ICaseSensitivity[] | null;
  caseSymptoms?: ICaseSymptom[] | null;
  caseDosageForms?: ICaseDosageForm[] | null;
  caseDietaryPreferences?: ICaseDietaryPreference[] | null;
  caseMedicalConditions?: ICaseMedicalCondition[] | null;
  caseComputeMedicalConditions?: ICaseComputeMedicalCondition[] | null;
  caseMedicalConditionGroups?: ICaseMedicalConditionGroup[] | null;
  casePageContents?: ICasePageContent[] | null;
  productsTailorFitCases?: IProductsTailorFitCase[] | null;
}

export interface ICasePageContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  casePageID?: string | null;
  casePage?: ICasePage;
}

export interface ICasePageContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICasePageContent[];
}

export interface ICasePageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICasePage[];
}

export interface ICasePageType {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  cpType?: ICPType;
  casePages?: ICasePage[] | null;
}

export interface ICasePageTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICasePageType[];
}

export interface ICaseSensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  sensitivityID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  casePage?: ICasePage;
  sensitivity?: ISensitivity;
}

export interface ICaseSensitivityDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseSensitivity[];
}

export interface ICaseSymptom {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  symptomID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  casePage?: ICasePage;
  symptom?: ISymptom;
}

export interface ICaseSymptomDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseSymptom[];
}

export interface ICaseTag {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  caseID?: string | null;
  case?: ICase;
}

export interface ICaseTagDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseTag[];
}

export interface ICloneCaseRequest {
  caseID?: string | null;
  countryID?: string | null;
  title?: string | null;
  tags?: boolean;
  products?: boolean;
}

export interface ICloudFileViewModel {
  fileName?: string | null;
  fileStreamString?: string | null;
  filePath?: string | null;
  isImage?: boolean;
}

export interface ICommonPage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  languageID?: string | null;
  commonPageType?: IPageType;
  description?: string | null;
  htmlContent?: string | null;
  html?: string | null;
  picture?: string | null;
  canonical?: string | null;
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaKeyWords?: string | null;
  language?: ILanguage;
  commonPageSections?: ICommonPageSection[] | null;
}

export interface ICommonPageSection {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  commonPageID?: string | null;
  sectionType?: ISectionType;
  htmlContent?: string | null;
  picture?: string | null;
  video?: string | null;
  commonPage?: ICommonPage;
  commonPageSectionItems?: ICommonPageSectionItem[] | null;
}

export interface ICommonPageSectionItem {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  commonPageSectionID?: string | null;
  description?: string | null;
  htmlContent?: string | null;
  picture?: string | null;
  video?: string | null;
  pictureLarge?: string | null;
  cssClass?: string | null;
  /** @format int32 */
  rank?: number;
  isSecondary?: boolean;
  commonPageSection?: ICommonPageSection;
}

export interface ICountry {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryName?: string | null;
  flag?: string | null;
  countryCode?: string | null;
  internationalDailingCode?: string | null;
  highlight?: boolean;
  pharmaCompanies?: IPharmaCompany[] | null;
  pharmaCompanyOrders?: IPharmaCompanyOrder[] | null;
  products?: IProduct[] | null;
  tenants?: ITenant[] | null;
  cases?: ICase[] | null;
  countryLanguages?: ICountryLanguage[] | null;
}

export interface ICountryDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICountry[];
}

export interface ICountryLanguage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryID?: string | null;
  languageID?: string | null;
  country?: ICountry;
  language?: ILanguage;
}

export interface ICountryLanguageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICountryLanguage[];
}

export interface ICurrentUserProfileResponse {
  appIdentityUserID?: string | null;
  languageID?: string | null;
  name?: string | null;
  userPhoto?: string | null;
  email?: string | null;
  userCrmProfilePermission?: IUserCrmProfilePermission;
  isActive?: boolean;
}

export interface IDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  dietaryPreferenceContents?: IDietaryPreferenceContent[] | null;
  caseDietaryPreferences?: ICaseDietaryPreference[] | null;
  productDietaryPreferences?: IProductDietaryPreference[] | null;
  botSessionDietaryPreferences?: IBotSessionDietaryPreference[] | null;
}

export interface IDietaryPreferenceContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dietaryPreferenceID?: string | null;
  dietaryPreference?: IDietaryPreference;
}

export interface IDietaryPreferenceContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDietaryPreferenceContent[];
}

export interface IDietaryPreferenceDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDietaryPreference[];
}

export interface IDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  dosageFormCategoryID?: string | null;
  dosageFormUnitTypeID?: string | null;
  catalogName?: string | null;
  dosageFormCategory?: IDosageFormCategory;
  dosageFormUnitType?: IDosageFormUnitType;
  dosageFormContents?: IDosageFormContent[] | null;
  caseDosageForms?: ICaseDosageForm[] | null;
  products?: IProduct[] | null;
  botSessionDosageForms?: IBotSessionDosageForm[] | null;
  productsTailorFitSessionDosageForms?: IProductsTailorFitSessionDosageForm[] | null;
}

export interface IDosageFormCategory {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  dosageForms?: IDosageForm[] | null;
  dosageFormCategoryContents?: IDosageFormCategoryContent[] | null;
}

export interface IDosageFormCategoryContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dosageFormCategoryID?: string | null;
  dosageFormCategory?: IDosageFormCategory;
}

export interface IDosageFormCategoryContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormCategoryContent[];
}

export interface IDosageFormCategoryDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormCategory[];
}

export interface IDosageFormContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dosageFormID?: string | null;
  dosageForm?: IDosageForm;
}

export interface IDosageFormContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormContent[];
}

export interface IDosageFormDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageForm[];
}

export interface IDosageFormUnitType {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  dosageFormUnitTypeContents?: IDosageFormUnitTypeContent[] | null;
  dosageForms?: IDosageForm[] | null;
}

export interface IDosageFormUnitTypeContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dosageFormUnitTypeID?: string | null;
  dosageFormUnitType?: IDosageFormUnitType;
}

export interface IDosageFormUnitTypeContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormUnitTypeContent[];
}

export interface IDosageFormUnitTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormUnitType[];
}

export interface IFamilyMember {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  genderID?: string | null;
  /** @format date-time */
  dateOfBirth?: string | Date | null;
  fullName?: string | null;
  userPhoto?: string | null;
  userFamilyProfile?: IUserFamilyProfile;
  gender?: IGender;
  botSessions?: IBotSession[] | null;
}

export interface IGender {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  isBothOrNotRelevant?: boolean;
  genderContents?: IGenderContent[] | null;
  products?: IProduct[] | null;
  cases?: ICase[] | null;
  medicalConditions?: IMedicalCondition[] | null;
  symptoms?: ISymptom[] | null;
  redFlags?: IRedFlag[] | null;
  userFamilyProfiles?: IUserFamilyProfile[] | null;
  familyMembers?: IFamilyMember[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  languages?: ILanguage[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
  casePages?: ICasePage[] | null;
}

export interface IGenderContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  genderID?: string | null;
  gender?: IGender;
}

export interface IGenderContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IGenderContent[];
}

export interface IGenderDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IGender[];
}

export interface IGeneratePasswordRequest {
  email?: string | null;
  rememberMe?: boolean;
  languageID?: string | null;
}

export interface IGlobalParameter {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  version?: string | null;
  generalBodyScripts?: string | null;
  generalFooterScripts?: string | null;
  generalHeaderScripts?: string | null;
  conversionCodeScripts?: string | null;
}

export interface IGlobalParameterDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IGlobalParameter[];
}

export interface IHealthCareTip {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  healthCareTipContents?: IHealthCareTipContent[] | null;
  caseHealthCareTips?: ICaseHealthCareTip[] | null;
}

export interface IHealthCareTipContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  healthCareTipID?: string | null;
  healthCareTip?: IHealthCareTip;
}

export interface IHealthCareTipContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IHealthCareTipContent[];
}

export interface IHealthCareTipDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IHealthCareTip[];
}

export enum IIconPosition {
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
  MidLeft = 'MidLeft',
  MidRight = 'MidRight',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
}

export interface IJwt {
  token?: string | null;
  refreshToken?: string | null;
}

export interface ILabel {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  languageID?: string | null;
  labelSourceID?: string | null;
  labelKey?: string | null;
  labelSource?: ILabelSource;
  language?: ILanguage;
}

export interface ILabelDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILabel[];
}

export interface ILabelSource {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  key?: string | null;
  labels?: ILabel[] | null;
}

export interface ILabelSourceDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILabelSource[];
}

export interface ILanguage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  direction?: ILanguageDirection;
  genderID?: string | null;
  culture?: string | null;
  icon?: string | null;
  currency?: string | null;
  importName?: string | null;
  isoCode?: string | null;
  /** @format int32 */
  rank?: number;
  isMajor?: boolean;
  isRTL?: boolean;
  gender?: IGender;
  labels?: ILabel[] | null;
  commonPages?: ICommonPage[] | null;
  userProfileFamilies?: IUserFamilyProfile[] | null;
  userProfileCRMs?: IUserCrmProfile[] | null;
  siteParameters?: ISiteParameter[] | null;
  botWelcomeMessages?: IBotWelcomeMessage[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
  productsTailorFitUserProfileContents?: IProductsTailorFitUserProfileContent[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  tenantBotContents?: ITenantBotContent[] | null;
  countryLanguages?: ICountryLanguage[] | null;
  redisParams?: IRedisParam[] | null;
}

export enum ILanguageDirection {
  LTR = 'LTR',
  RTL = 'RTL',
}

export interface ILanguageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILanguage[];
}

export interface ILoginRequest {
  email?: string | null;
  code?: string | null;
  rememberMe?: boolean;
  languageID?: string | null;
}

export interface ILoginResponse {
  jwt?: IJwt;
  /** @format date-time */
  expires?: string | Date;
}

export interface IMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  genderID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  gender?: IGender;
  medicalConditionContents?: IMedicalConditionContent[] | null;
  caseMedicalConditions?: ICaseMedicalCondition[] | null;
  productMedicalConditions?: IProductMedicalCondition[] | null;
  botSessionMedicalConditions?: IBotSessionMedicalCondition[] | null;
  medicalConditionIngredients?: IMedicalConditionIngredient[] | null;
}

export interface IMedicalConditionContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  medicalConditionID?: string | null;
  medicalCondition?: IMedicalCondition;
}

export interface IMedicalConditionContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalConditionContent[];
}

export interface IMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalCondition[];
}

export interface IMedicalConditionIngredient {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  medicalConditionID?: string | null;
  ingredientID?: string | null;
  catalogName?: string | null;
  warning?: boolean;
  displayInstructions?: boolean;
  medicalCondition?: IMedicalCondition;
  ingredient?: ISensitivity;
  medicalConditionIngredientContents?: IMedicalConditionIngredientContent[] | null;
}

export interface IMedicalConditionIngredientContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  medicalConditionIngredientID?: string | null;
  medicalConditionIngredient?: IMedicalConditionIngredient;
}

export interface IMedicalConditionIngredientContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalConditionIngredientContent[];
}

export interface IMedicalConditionIngredientDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalConditionIngredient[];
}

export interface IMedication {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  medicationContents?: IMedicationContent[] | null;
  caseMedications?: ICaseMedication[] | null;
  products?: IProduct[] | null;
  productMedications?: IProductMedication[] | null;
}

export interface IMedicationContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  medicationID?: string | null;
  medication?: IMedication;
}

export interface IMedicationContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicationContent[];
}

export interface IMedicationDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedication[];
}

export interface IOperation {
  operationType?: IOperationType;
  path?: string | null;
  op?: string | null;
  from?: string | null;
  value?: any;
}

export enum IOperationType {
  Add = 'Add',
  Remove = 'Remove',
  Replace = 'Replace',
  Move = 'Move',
  Copy = 'Copy',
  Test = 'Test',
  Invalid = 'Invalid',
}

export interface IOrderStatus {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  orderStatusType?: IOrderStatusType;
  pharmaCompanyOrders?: IPharmaCompanyOrder[] | null;
}

export interface IOrderStatusDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IOrderStatus[];
}

export enum IOrderStatusType {
  Active = 'Active',
  Pending = 'Pending',
  Trial = 'Trial',
  Archive = 'Archive',
}

export enum IPageType {
  Promo = 'Promo',
  Platform = 'Platform',
  Portal = 'Portal',
  Privacy = 'Privacy',
  Terms = 'Terms',
  WhoAreWe = 'WhoAreWe',
  WhatDoWeOffer = 'WhatDoWeOffer',
  WhyPharmAssist = 'WhyPharmAssist',
  TreatYourself = 'TreatYourself',
  Faq = 'Faq',
  CompanyOverview = 'CompanyOverview',
  Team = 'Team',
  Traction = 'Traction',
  Contact = 'Contact',
  Thanks = 'Thanks',
}

export interface IPharmaCompany {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryID?: string | null;
  companyName?: string | null;
  country?: ICountry;
  pharmaCompanyOrders?: IPharmaCompanyOrder[] | null;
  products?: IProduct[] | null;
}

export interface IPharmaCompanyDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompany[];
}

export interface IPharmaCompanyOrder {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyID?: string | null;
  orderStatusID?: string | null;
  countryID?: string | null;
  orderNumber?: string | null;
  invoiceNumber?: string | null;
  /** @format double */
  pricePerDay?: number | null;
  /** @format double */
  totalPrice?: number | null;
  /** @format int32 */
  maxProducts?: number | null;
  /** @format date-time */
  startDate?: string | Date;
  /** @format date-time */
  endDate?: string | Date;
  pharmaCompany?: IPharmaCompany;
  orderStatus?: IOrderStatus;
  country?: ICountry;
  pharmaCompanyOrderDetails?: IPharmaCompanyOrderDetail[] | null;
  pharmaCompanyOrderTenants?: IPharmaCompanyOrderTenant[] | null;
  botCaseTenantProducts?: IBotCaseTenantProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
}

export interface IPharmaCompanyOrderDetail {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  productID?: string | null;
  /** @format double */
  maxPricePerDay?: number | null;
  /** @format double */
  priceForView?: number | null;
  /** @format double */
  priceForClick?: number | null;
  /** @format double */
  priceForCart?: number | null;
  /** @format int32 */
  positionIndex?: number;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
  product?: IProduct;
}

export interface IPharmaCompanyOrderDetailDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompanyOrderDetail[];
}

export interface IPharmaCompanyOrderDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompanyOrder[];
}

export interface IPharmaCompanyOrderTenant {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  tenantID?: string | null;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
  tenant?: ITenant;
}

export interface IPharmaCompanyOrderTenantDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompanyOrderTenant[];
}

export interface IProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  drugRegNumber?: string | null;
  mainMedicationID?: string | null;
  productBrandID?: string | null;
  dosageFormID?: string | null;
  genderID?: string | null;
  pharmaCompanyID?: string | null;
  countryID?: string | null;
  catalogName?: string | null;
  /** @format double */
  quantity?: number | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  isBrand?: boolean;
  isGeneric?: boolean;
  isOverTheCounter?: boolean;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  signatureApprovalMain?: boolean;
  signatureApprovalSecondary?: boolean;
  gender?: IGender;
  productBrand?: IProductBrand;
  mainMedication?: IMedication;
  dosageForm?: IDosageForm;
  pharmaCompany?: IPharmaCompany;
  country?: ICountry;
  productContents?: IProductContent[] | null;
  filterProductSymptoms?: IProductSymptom[] | null;
  filterProductMedicalConditions?: IProductMedicalCondition[] | null;
  filterProductSensitivities?: IProductSensitivity[] | null;
  productIngredients?: IProductIngredient[] | null;
  filterProductMedications?: IProductMedication[] | null;
  productDietaryPreferences?: IProductDietaryPreference[] | null;
  tenantProducts?: ITenantProduct[] | null;
  productCases?: IProductCase[] | null;
  pharmaCompanyOrderDetails?: IPharmaCompanyOrderDetail[] | null;
  productLeaflets?: IProductLeaflet[] | null;
}

export interface IProductBrand {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  products?: IProduct[] | null;
  productBrandContents?: IProductBrandContent[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  tenantProductBrands?: ITenantProductBrand[] | null;
}

export interface IProductBrandContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  productBrandID?: string | null;
  productBrand?: IProductBrand;
}

export interface IProductBrandContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductBrandContent[];
}

export interface IProductBrandDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductBrand[];
}

export interface IProductCase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  caseID?: string | null;
  product?: IProduct;
  case?: ICase;
}

export interface IProductCaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductCase[];
}

export interface IProductContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  productID?: string | null;
  product?: IProduct;
}

export interface IProductContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductContent[];
}

export interface IProductDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  dietaryPreferenceID?: string | null;
  product?: IProduct;
  dietaryPreference?: IDietaryPreference;
}

export interface IProductDietaryPreferenceDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductDietaryPreference[];
}

export interface IProductDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProduct[];
}

export interface IProductIngredient {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  sensitivityID?: string | null;
  product?: IProduct;
  sensitivity?: ISensitivity;
}

export interface IProductIngredientDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductIngredient[];
}

export interface IProductLeaflet {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  productID?: string | null;
  comments?: string | null;
  /** @format date-time */
  leafletUpdatedDate?: string | Date | null;
  filePath?: string | null;
  product?: IProduct;
}

export interface IProductLeafletDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductLeaflet[];
}

export interface IProductMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  medicalConditionID?: string | null;
  product?: IProduct;
  medicalCondition?: IMedicalCondition;
}

export interface IProductMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductMedicalCondition[];
}

export interface IProductMedication {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  medicationID?: string | null;
  product?: IProduct;
  medication?: IMedication;
}

export interface IProductMedicationDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductMedication[];
}

export interface IProductSensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  sensitivityID?: string | null;
  product?: IProduct;
  sensitivity?: ISensitivity;
}

export interface IProductSensitivityDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductSensitivity[];
}

export interface IProductSymptom {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  symptomID?: string | null;
  product?: IProduct;
  symptom?: ISymptom;
}

export interface IProductSymptomDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductSymptom[];
}

export interface IProductsTailorFitCase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  caseID?: string | null;
  casePageID?: string | null;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
  case?: ICase;
  casePage?: ICasePage;
}

export interface IProductsTailorFitCaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitCase[];
}

export interface IProductsTailorFitSession {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  appIdentityUserID?: string | null;
  uniqueSessionUserID?: string | null;
  genderID?: string | null;
  languageID?: string | null;
  caseID?: string | null;
  productsTailorFitSessionStepID?: string | null;
  /** @format double */
  age?: number | null;
  flowType?: string | null;
  reference?: string | null;
  url?: string | null;
  location?: string | null;
  longitude?: string | null;
  latitude?: string | null;
  deviceType?: string | null;
  tenant?: ITenant;
  case?: ICase;
  gender?: IGender;
  language?: ILanguage;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
  productsTailorFitSessionStep?: IProductsTailorFitSessionStep;
  productsTailorFitSessionLogs?: IProductsTailorFitSessionLog[] | null;
  productsTailorFitSessionDosageForms?: IProductsTailorFitSessionDosageForm[] | null;
  productsTailorFitSessionProducts?: IProductsTailorFitSessionProduct[] | null;
}

export interface IProductsTailorFitSessionDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productsTailorFitSessionID?: string | null;
  dosageFormID?: string | null;
  chosen?: boolean;
  dosageForm?: IDosageForm;
  productsTailorFitSession?: IProductsTailorFitSession;
}

export interface IProductsTailorFitSessionLog {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productsTailorFitSessionID?: string | null;
  /** @format date-time */
  eventDate?: string | Date;
  /** @format int32 */
  orderIndex?: number;
  flowType?: string | null;
  caseID?: string | null;
  userActionType?: string | null;
  userActionKey?: string | null;
  userActionValue?: string | null;
  userActionEntityID?: string | null;
  productsTailorFitSession?: IProductsTailorFitSession;
}

export interface IProductsTailorFitSessionProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productsTailorFitSessionID?: string | null;
  tenantProductID?: string | null;
  /** @format int32 */
  positionIndex?: number;
  isPromoted?: boolean;
  productsTailorFitSession?: IProductsTailorFitSession;
  tenantProduct?: ITenantProduct;
}

export interface IProductsTailorFitSessionStep {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  productsTailorFitSessionStepOption?: IProductsTailorFitSessionStepOption;
  isSessionComplete?: boolean;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
}

export enum IProductsTailorFitSessionStepOption {
  Init = 'Init',
  ProductsDiscovery = 'ProductsDiscovery',
}

export interface IProductsTailorFitTheme {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  description?: string | null;
  textPrimary?: string | null;
  textSecondary?: string | null;
  warningColor?: string | null;
  warningContrastColor?: string | null;
  errorColor?: string | null;
  errorContrastColor?: string | null;
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
  primaryGradientColor?: string | null;
  secondaryColor?: string | null;
  secondaryContrastColor?: string | null;
  secondaryGradientColor?: string | null;
  bgPrimary?: string | null;
  bgSecondary?: string | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
}

export interface IProductsTailorFitThemeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitTheme[];
}

export interface IProductsTailorFitUserProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  tenantID?: string | null;
  genderID?: string | null;
  productsTailorFitThemeID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  propertyCode?: string | null;
  secret?: string | null;
  currency?: string | null;
  htmlTagContainer?: string | null;
  /** @format int32 */
  zIndex?: number;
  applyAddToCart?: boolean;
  addToCartCode?: string | null;
  trackLog?: boolean;
  internal?: boolean;
  dispalyAge?: boolean;
  appIdentityUser?: IAppIdentityUser;
  gender?: IGender;
  tenant?: ITenant;
  language?: ILanguage;
  productsTailorFitTheme?: IProductsTailorFitTheme;
  productsTailorFitCases?: IProductsTailorFitCase[] | null;
  productsTailorFitUserProfileContents?: IProductsTailorFitUserProfileContent[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
}

export interface IProductsTailorFitUserProfileContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  /** @format int32 */
  containerMaxWidth?: number;
  title?: string | null;
  subTitle?: string | null;
  ageAndGenderLabel?: string | null;
  icon?: string | null;
  banner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditionsHtmlContent?: string | null;
  privacyPolicyHtmlContent?: string | null;
  termsAndPrivacyLabel?: string | null;
  openBotLabel?: string | null;
  tipsLabel?: string | null;
  productSubTitleLabel?: string | null;
  language?: ILanguage;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
}

export enum IRFType {
  YesNo = 'YesNo',
  PainScale = 'PainScale',
  Survey = 'Survey',
}

export interface IRedFlag {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  redFlagPageID?: string | null;
  redFlagTypeID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  catalogName?: string | null;
  genderID?: string | null;
  gender?: IGender;
  defaultIsYes?: boolean | null;
  defaultIsNo?: boolean | null;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  /** @format int32 */
  referFromScore?: number | null;
  redFlagOptions?: IRedFlagOption[] | null;
  redFlagPage?: IRedFlagPage;
  redFlagType?: IRedFlagType;
  redFlagContents?: IRedFlagContent[] | null;
}

export interface IRedFlagContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  redFlagID?: string | null;
  redFlag?: IRedFlag;
}

export interface IRedFlagContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagContent[];
}

export interface IRedFlagDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlag[];
}

export interface IRedFlagOption {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  redFlagID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  catalogName?: string | null;
  /** @format int32 */
  score?: number;
  color?: string | null;
  redFlag?: IRedFlag;
  redFlagOptionContents?: IRedFlagOptionContent[] | null;
}

export interface IRedFlagOptionContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  redFlagOptionID?: string | null;
  redFlagOption?: IRedFlagOption;
}

export interface IRedFlagOptionContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagOptionContent[];
}

export interface IRedFlagOptionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagOption[];
}

export interface IRedFlagPage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  /** @format int32 */
  pageNumber?: number;
  catalogName?: string | null;
  /** @format int32 */
  minScoreToRefer?: number | null;
  casePage?: ICasePage;
  redFlagPageContents?: IRedFlagPageContent[] | null;
  redFlags?: IRedFlag[] | null;
}

export interface IRedFlagPageContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  redFlagPageID?: string | null;
  redFlagPage?: IRedFlagPage;
}

export interface IRedFlagPageContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagPageContent[];
}

export interface IRedFlagPageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagPage[];
}

export interface IRedFlagType {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  rfType?: IRFType;
  displayOptions?: boolean;
  redFlags?: IRedFlag[] | null;
}

export interface IRedFlagTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagType[];
}

export interface IRedisParam {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  source?: string | null;
  key?: string | null;
  description?: string | null;
  tenantID?: string | null;
  caseID?: string | null;
  languageID?: string | null;
  /** @format date-time */
  lastUpdatedDate?: string | Date;
  case?: ICase;
  tenant?: ITenant;
  language?: ILanguage;
}

export interface IRedisParamDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedisParam[];
}

export interface IRelationshipBulk {
  mainID?: string | null;
  itemIDs?: string[] | null;
}

export enum ISectionType {
  Section1 = 'Section1',
  Section2 = 'Section2',
}

export interface ISensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  isMultiVitamin?: boolean;
  sensitivityContents?: ISensitivityContent[] | null;
  caseSensitivities?: ICaseSensitivity[] | null;
  productSensitivities?: IProductSensitivity[] | null;
  botSessionSensitivities?: IBotSessionSensitivity[] | null;
  productIngredients?: IProductIngredient[] | null;
  medicalConditionIngredients?: IMedicalConditionIngredient[] | null;
}

export interface ISensitivityContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  sensitivityID?: string | null;
  sensitivity?: ISensitivity;
}

export interface ISensitivityContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISensitivityContent[];
}

export interface ISensitivityDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISensitivity[];
}

export enum ISessionStep {
  Init = 'Init',
  Information = 'Information',
  StartFlow = 'StartFlow',
  Refer = 'Refer',
  Stop = 'Stop',
  MidFlow = 'MidFlow',
  CompleteFlow = 'CompleteFlow',
  CreateUser = 'CreateUser',
  ProductsDiscovery = 'ProductsDiscovery',
}

export interface ISiteParameter {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  htmlContent?: string | null;
  copyright?: string | null;
  logo?: string | null;
  slogan?: string | null;
  videoURL?: string | null;
  phone1?: string | null;
  phone2?: string | null;
  email?: string | null;
  address?: string | null;
  bannerPicture?: string | null;
  bannerPictureSmall?: string | null;
  bannerLinkURL?: string | null;
  displayBanner?: boolean;
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaKeyWords?: string | null;
  canonical?: string | null;
  language?: ILanguage;
  siteParameterSocialMedias?: ISiteParameterSocialMedia[] | null;
}

export interface ISiteParameterSocialMedia {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  siteParameterID?: string | null;
  icon1?: string | null;
  icon2?: string | null;
  linkURL?: string | null;
  description?: string | null;
  /** @format int32 */
  rank?: number;
  siteParameter?: ISiteParameter;
}

export interface ISymptom {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  genderID?: string | null;
  gender?: IGender;
  symptomContents?: ISymptomContent[] | null;
  caseSymptoms?: ICaseSymptom[] | null;
  productSymptoms?: IProductSymptom[] | null;
}

export interface ISymptomContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  symptomID?: string | null;
  symptom?: ISymptom;
}

export interface ISymptomContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISymptomContent[];
}

export interface ISymptomDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISymptom[];
}

export interface ITenant {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryID?: string | null;
  companyName?: string | null;
  logo?: string | null;
  /** @format double */
  botPrice?: number | null;
  /** @format double */
  scenarioPackagePrice?: number | null;
  /** @format double */
  completeSessionPrice?: number | null;
  fullStoryCode?: string | null;
  googleAnalyticsCode?: string | null;
  country?: ICountry;
  tenantUserFamilyProfiles?: ITenantUserFamilyProfile[] | null;
  tenantProducts?: ITenantProduct[] | null;
  tenantIgnoreProducts?: ITenantIgnoreProduct[] | null;
  userTenants?: IUserTenant[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  tenantProductBrands?: ITenantProductBrand[] | null;
  pharmaCompanyOrderTenants?: IPharmaCompanyOrderTenant[] | null;
  tenantBotContents?: ITenantBotContent[] | null;
  redisParams?: IRedisParam[] | null;
}

export interface ITenantBotContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  languageID?: string | null;
  welcomeTitle?: string | null;
  welcomeSubTitle?: string | null;
  welcomeMessage?: string | null;
  welcomeBanner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditions?: string | null;
  privacyPolicy?: string | null;
  termsAndConditionsHtmlContent?: string | null;
  privacyPolicyHtmlContent?: string | null;
  termsAndPrivacyLabel?: string | null;
  helpMeLabel?: string | null;
  productSubTitleLabel?: string | null;
  language?: ILanguage;
  tenant?: ITenant;
}

export interface ITenantDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ITenant[];
}

export interface ITenantIgnoreProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  productName?: string | null;
  serialNumber?: string | null;
  tenant?: ITenant;
}

export interface ITenantProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  productID?: string | null;
  shopProductID?: string | null;
  serialNumber?: string | null;
  linkURL?: string | null;
  /** @format double */
  price?: number | null;
  /** @format double */
  priceSale?: number | null;
  priceSaleDescription?: string | null;
  isPending?: boolean;
  tenant?: ITenant;
  product?: IProduct;
  botSessionProducts?: IBotSessionProduct[] | null;
  productsTailorFitSessionProducts?: IProductsTailorFitSessionProduct[] | null;
  tenantProductContents?: ITenantProductContent[] | null;
  botCaseTenantProducts?: IBotCaseTenantProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
}

export interface ITenantProductBrand {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  productBrandID?: string | null;
  /** @format int32 */
  rank?: number;
  tenant?: ITenant;
  productBrand?: IProductBrand;
}

export interface ITenantProductContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  tenantProductID?: string | null;
  tenantProduct?: ITenantProduct;
}

export interface ITenantUserFamilyProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  appIdentityUserID?: string | null;
  tenant?: ITenant;
  userFamilyProfile?: IUserFamilyProfile;
}

export interface IUserCrmProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  userCrmProfilePermissionID?: string | null;
  languageID?: string | null;
  /** @format date-time */
  generatePasswordEndDate?: string | Date | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  userPhoto?: string | null;
  appIdentityUser?: IAppIdentityUser;
  userCrmProfilePermission?: IUserCrmProfilePermission;
  language?: ILanguage;
}

export interface IUserCrmProfileDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserCrmProfile[];
}

export interface IUserCrmProfilePermission {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  isAllowToEditWebsites?: boolean;
  isAllowToEditSetting?: boolean;
  isAllowToEditTenants?: boolean;
  isAllowToEditFlows?: boolean;
  isAllowToEditLabels?: boolean;
  isAllowToEditProfile?: boolean;
  isAllowToEditCRMUsers?: boolean;
  isAllowToEditPermissions?: boolean;
  isAllowToViewLog?: boolean;
  isAllowToViewSensitiveInformation?: boolean;
  isAllowToResetSensitiveInformation?: boolean;
  userCrmProfiles?: IUserCrmProfile[] | null;
}

export interface IUserCrmProfilePermissionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserCrmProfilePermission[];
}

export interface IUserFamilyProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  genderID?: string | null;
  /** @format date-time */
  generatePasswordEndDate?: string | Date | null;
  email?: string | null;
  mobilePhone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  /** @format date-time */
  dateOfBirth?: string | Date | null;
  userPhoto?: string | null;
  gender?: IGender;
  appIdentityUser?: IAppIdentityUser;
  language?: ILanguage;
  familyMembers?: IFamilyMember[] | null;
  tenantUserFamilyProfiles?: ITenantUserFamilyProfile[] | null;
  botSessions?: IBotSession[] | null;
}

export interface IUserTenant {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  appIdentityUserID?: string | null;
  tenant?: ITenant;
  userTenantProfile?: IUserTenantProfile;
}

export interface IUserTenantDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserTenant[];
}

export interface IUserTenantProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  /** @format date-time */
  generatePasswordEndDate?: string | Date | null;
  email?: string | null;
  mobilePhone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  isAdmin?: boolean;
  fullName?: string | null;
  appIdentityUser?: IAppIdentityUser;
  userTenants?: IUserTenant[] | null;
}

export interface IUserTenantProfileDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserTenantProfile[];
}

export interface IAppLogsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IAppLogSourcesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IAppLogTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBodyPartContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBodyPartsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotCasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotThemesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotUserProfilesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseBodyPartsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseCategoriesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseCategoryContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseComputeMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseDecisionContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseDecisionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseDietaryPreferencesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseDosageFormsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseHealthCareTipsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicalConditionGroupContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicalConditionGroupsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicationsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasePageContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasePagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasePageTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasesGetContentListParams {
  caseID?: string;
}

export interface ICaseSensitivitiesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseSymptomsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseTagsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICountriesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICountryLanguagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDietaryPreferenceContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDietaryPreferencesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormCategoriesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormCategoryContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormUnitTypeContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormUnitTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IGenderContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IGendersGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IGlobalParametersGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IHealthCareTipContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IHealthCareTipsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILabelsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILabelSourcesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILanguagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMediaUploadsRemoveFileFromCloudUpdateParams {
  filePath?: string;
  isImage?: boolean;
}

export interface IMedicalConditionContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicalConditionIngredientContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicalConditionIngredientsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicationContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicationsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IOrderStatusesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompaniesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompanyOrderDetailsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompanyOrdersGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompanyOrderTenantsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductBrandContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductBrandsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductCasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductDietaryPreferencesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductIngredientsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductLeafletsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductMedicationsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductSensitivitiesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitCasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitThemesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitUserProfilesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductSymptomsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagOptionContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagOptionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagPageContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagPagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedisParamsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISensitivitiesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISensitivityContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISymptomContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISymptomsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITenantsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserCrmProfileGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserCrmProfilePermissionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserTenantProfilesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserTenantsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor(
    { securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {},
  ) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>(
    { secure, path, type, query, format, body, ...params }: FullRequestParams,
  ): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title ApoWiser Pro API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGeneratePasswordCreate
     * @request POST:/api/Accounts/GeneratePassword
     * @secure
     */
    accountsGeneratePasswordCreate: (data: IGeneratePasswordRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Accounts/GeneratePassword`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLoginWithCodeCreate
     * @request POST:/api/Accounts/LoginWithCode
     * @secure
     */
    accountsLoginWithCodeCreate: (data: ILoginRequest, params: RequestParams = {}) =>
      this.request<ILoginResponse, any>({
        path: `/api/Accounts/LoginWithCode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLogoutCreate
     * @request POST:/api/Accounts/Logout
     * @secure
     */
    accountsLogoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Accounts/Logout`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsRefreshTokenCreate
     * @request POST:/api/Accounts/RefreshToken
     * @secure
     */
    accountsRefreshTokenCreate: (data: IJwt, params: RequestParams = {}) =>
      this.request<IJwt, any>({
        path: `/api/Accounts/RefreshToken`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGetCurrentAppUserList
     * @request GET:/api/Accounts/GetCurrentAppUser
     * @secure
     */
    accountsGetCurrentAppUserList: (params: RequestParams = {}) =>
      this.request<ICurrentUserProfileResponse, any>({
        path: `/api/Accounts/GetCurrentAppUser`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogs
     * @name AppLogsGetAllDynamicList
     * @request GET:/api/AppLogs/GetAllDynamic
     * @secure
     */
    appLogsGetAllDynamicList: (
      query: IAppLogsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IAppLogDynamicResponse, any>({
        path: `/api/AppLogs/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogSources
     * @name AppLogSourcesGetAllDynamicList
     * @request GET:/api/AppLogSources/GetAllDynamic
     * @secure
     */
    appLogSourcesGetAllDynamicList: (
      query: IAppLogSourcesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IAppLogSourceDynamicResponse, any>({
        path: `/api/AppLogSources/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogTypes
     * @name AppLogTypesGetAllDynamicList
     * @request GET:/api/AppLogTypes/GetAllDynamic
     * @secure
     */
    appLogTypesGetAllDynamicList: (
      query: IAppLogTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IAppLogTypeDynamicResponse, any>({
        path: `/api/AppLogTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyPartContents
     * @name BodyPartContentsGetAllDynamicList
     * @request GET:/api/BodyPartContents/GetAllDynamic
     * @secure
     */
    bodyPartContentsGetAllDynamicList: (
      query: IBodyPartContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBodyPartContentDynamicResponse, any>({
        path: `/api/BodyPartContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyPartContents
     * @name BodyPartContentsCreateCreate
     * @request POST:/api/BodyPartContents/Create
     * @secure
     */
    bodyPartContentsCreateCreate: (data: IBodyPartContent, params: RequestParams = {}) =>
      this.request<IBodyPartContent, any>({
        path: `/api/BodyPartContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyPartContents
     * @name BodyPartContentsPatchPartialUpdate
     * @request PATCH:/api/BodyPartContents/Patch/{id}
     * @secure
     */
    bodyPartContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/BodyPartContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyPartContents
     * @name BodyPartContentsDeleteDelete
     * @request DELETE:/api/BodyPartContents/Delete/{id}
     * @secure
     */
    bodyPartContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBodyPartContent, any>({
        path: `/api/BodyPartContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyParts
     * @name BodyPartsGetAllDynamicList
     * @request GET:/api/BodyParts/GetAllDynamic
     * @secure
     */
    bodyPartsGetAllDynamicList: (
      query: IBodyPartsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBodyPartDynamicResponse, any>({
        path: `/api/BodyParts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyParts
     * @name BodyPartsCreateCreate
     * @request POST:/api/BodyParts/Create
     * @secure
     */
    bodyPartsCreateCreate: (data: IBodyPart, params: RequestParams = {}) =>
      this.request<IBodyPart, any>({
        path: `/api/BodyParts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyParts
     * @name BodyPartsPatchPartialUpdate
     * @request PATCH:/api/BodyParts/Patch/{id}
     * @secure
     */
    bodyPartsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BodyParts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyParts
     * @name BodyPartsDeleteDelete
     * @request DELETE:/api/BodyParts/Delete/{id}
     * @secure
     */
    bodyPartsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBodyPart, any>({
        path: `/api/BodyParts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesGetAllDynamicList
     * @request GET:/api/BotCases/GetAllDynamic
     * @secure
     */
    botCasesGetAllDynamicList: (
      query: IBotCasesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotCaseDynamicResponse, any>({
        path: `/api/BotCases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesCreateCreate
     * @request POST:/api/BotCases/Create
     * @secure
     */
    botCasesCreateCreate: (data: IBotCase, params: RequestParams = {}) =>
      this.request<IBotCase, any>({
        path: `/api/BotCases/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesPatchPartialUpdate
     * @request PATCH:/api/BotCases/Patch/{id}
     * @secure
     */
    botCasesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotCases/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesDeleteDelete
     * @request DELETE:/api/BotCases/Delete/{id}
     * @secure
     */
    botCasesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBotCase, any>({
        path: `/api/BotCases/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesGetAllDynamicList
     * @request GET:/api/BotThemes/GetAllDynamic
     * @secure
     */
    botThemesGetAllDynamicList: (
      query: IBotThemesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotThemeDynamicResponse, any>({
        path: `/api/BotThemes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesCreateCreate
     * @request POST:/api/BotThemes/Create
     * @secure
     */
    botThemesCreateCreate: (data: IBotTheme, params: RequestParams = {}) =>
      this.request<IBotTheme, any>({
        path: `/api/BotThemes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesPatchPartialUpdate
     * @request PATCH:/api/BotThemes/Patch/{id}
     * @secure
     */
    botThemesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotThemes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesDeleteDelete
     * @request DELETE:/api/BotThemes/Delete/{id}
     * @secure
     */
    botThemesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBotTheme, any>({
        path: `/api/BotThemes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesDeleteDelete
     * @request DELETE:/api/BotUserProfiles/Delete/{appUserID}
     * @secure
     */
    botUserProfilesDeleteDelete: (appUserId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/BotUserProfiles/Delete/${appUserId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesGetAllDynamicList
     * @request GET:/api/BotUserProfiles/GetAllDynamic
     * @secure
     */
    botUserProfilesGetAllDynamicList: (
      query: IBotUserProfilesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/BotUserProfiles/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesCreateCreate
     * @request POST:/api/BotUserProfiles/Create
     * @secure
     */
    botUserProfilesCreateCreate: (data: IBotUserProfile, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/BotUserProfiles/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesPatchPartialUpdate
     * @request PATCH:/api/BotUserProfiles/Patch/{appUserID}
     * @secure
     */
    botUserProfilesPatchPartialUpdate: (
      appUserId: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/BotUserProfiles/Patch/${appUserId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseBodyParts
     * @name CaseBodyPartsGetAllDynamicList
     * @request GET:/api/CaseBodyParts/GetAllDynamic
     * @secure
     */
    caseBodyPartsGetAllDynamicList: (
      query: ICaseBodyPartsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseBodyPartDynamicResponse, any>({
        path: `/api/CaseBodyParts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseBodyParts
     * @name CaseBodyPartsCreateCreate
     * @request POST:/api/CaseBodyParts/Create
     * @secure
     */
    caseBodyPartsCreateCreate: (data: ICaseBodyPart, params: RequestParams = {}) =>
      this.request<ICaseBodyPart, any>({
        path: `/api/CaseBodyParts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseBodyParts
     * @name CaseBodyPartsPatchPartialUpdate
     * @request PATCH:/api/CaseBodyParts/Patch/{id}
     * @secure
     */
    caseBodyPartsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CaseBodyParts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseBodyParts
     * @name CaseBodyPartsDeleteDelete
     * @request DELETE:/api/CaseBodyParts/Delete/{id}
     * @secure
     */
    caseBodyPartsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseBodyPart, any>({
        path: `/api/CaseBodyParts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategories
     * @name CaseCategoriesGetAllDynamicList
     * @request GET:/api/CaseCategories/GetAllDynamic
     * @secure
     */
    caseCategoriesGetAllDynamicList: (
      query: ICaseCategoriesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseCategoryDynamicResponse, any>({
        path: `/api/CaseCategories/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategories
     * @name CaseCategoriesCreateCreate
     * @request POST:/api/CaseCategories/Create
     * @secure
     */
    caseCategoriesCreateCreate: (data: ICaseCategory, params: RequestParams = {}) =>
      this.request<ICaseCategory, any>({
        path: `/api/CaseCategories/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategories
     * @name CaseCategoriesPatchPartialUpdate
     * @request PATCH:/api/CaseCategories/Patch/{id}
     * @secure
     */
    caseCategoriesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseCategories/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategories
     * @name CaseCategoriesDeleteDelete
     * @request DELETE:/api/CaseCategories/Delete/{id}
     * @secure
     */
    caseCategoriesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseCategory, any>({
        path: `/api/CaseCategories/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategoryContents
     * @name CaseCategoryContentsGetAllDynamicList
     * @request GET:/api/CaseCategoryContents/GetAllDynamic
     * @secure
     */
    caseCategoryContentsGetAllDynamicList: (
      query: ICaseCategoryContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseCategoryContentDynamicResponse, any>({
        path: `/api/CaseCategoryContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategoryContents
     * @name CaseCategoryContentsCreateCreate
     * @request POST:/api/CaseCategoryContents/Create
     * @secure
     */
    caseCategoryContentsCreateCreate: (data: ICaseCategoryContent, params: RequestParams = {}) =>
      this.request<ICaseCategoryContent, any>({
        path: `/api/CaseCategoryContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategoryContents
     * @name CaseCategoryContentsPatchPartialUpdate
     * @request PATCH:/api/CaseCategoryContents/Patch/{id}
     * @secure
     */
    caseCategoryContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseCategoryContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategoryContents
     * @name CaseCategoryContentsDeleteDelete
     * @request DELETE:/api/CaseCategoryContents/Delete/{id}
     * @secure
     */
    caseCategoryContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseCategoryContent, any>({
        path: `/api/CaseCategoryContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseComputeMedicalConditions
     * @name CaseComputeMedicalConditionsGetAllDynamicList
     * @request GET:/api/CaseComputeMedicalConditions/GetAllDynamic
     * @secure
     */
    caseComputeMedicalConditionsGetAllDynamicList: (
      query: ICaseComputeMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseComputeMedicalConditionDynamicResponse, any>({
        path: `/api/CaseComputeMedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseComputeMedicalConditions
     * @name CaseComputeMedicalConditionsCreateCreate
     * @request POST:/api/CaseComputeMedicalConditions/Create
     * @secure
     */
    caseComputeMedicalConditionsCreateCreate: (
      data: ICaseComputeMedicalCondition,
      params: RequestParams = {},
    ) =>
      this.request<ICaseComputeMedicalCondition, any>({
        path: `/api/CaseComputeMedicalConditions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseComputeMedicalConditions
     * @name CaseComputeMedicalConditionsPatchPartialUpdate
     * @request PATCH:/api/CaseComputeMedicalConditions/Patch/{id}
     * @secure
     */
    caseComputeMedicalConditionsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseComputeMedicalConditions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseComputeMedicalConditions
     * @name CaseComputeMedicalConditionsDeleteDelete
     * @request DELETE:/api/CaseComputeMedicalConditions/Delete/{id}
     * @secure
     */
    caseComputeMedicalConditionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseComputeMedicalCondition, any>({
        path: `/api/CaseComputeMedicalConditions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseContents
     * @name CaseContentsGetAllDynamicList
     * @request GET:/api/CaseContents/GetAllDynamic
     * @secure
     */
    caseContentsGetAllDynamicList: (
      query: ICaseContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseContentDynamicResponse, any>({
        path: `/api/CaseContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseContents
     * @name CaseContentsCreateCreate
     * @request POST:/api/CaseContents/Create
     * @secure
     */
    caseContentsCreateCreate: (data: ICaseContent, params: RequestParams = {}) =>
      this.request<ICaseContent, any>({
        path: `/api/CaseContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseContents
     * @name CaseContentsPatchPartialUpdate
     * @request PATCH:/api/CaseContents/Patch/{id}
     * @secure
     */
    caseContentsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CaseContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseContents
     * @name CaseContentsDeleteDelete
     * @request DELETE:/api/CaseContents/Delete/{id}
     * @secure
     */
    caseContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseContent, any>({
        path: `/api/CaseContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisionContents
     * @name CaseDecisionContentsGetAllDynamicList
     * @request GET:/api/CaseDecisionContents/GetAllDynamic
     * @secure
     */
    caseDecisionContentsGetAllDynamicList: (
      query: ICaseDecisionContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDecisionContentDynamicResponse, any>({
        path: `/api/CaseDecisionContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisionContents
     * @name CaseDecisionContentsCreateCreate
     * @request POST:/api/CaseDecisionContents/Create
     * @secure
     */
    caseDecisionContentsCreateCreate: (data: ICaseDecisionContent, params: RequestParams = {}) =>
      this.request<ICaseDecisionContent, any>({
        path: `/api/CaseDecisionContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisionContents
     * @name CaseDecisionContentsPatchPartialUpdate
     * @request PATCH:/api/CaseDecisionContents/Patch/{id}
     * @secure
     */
    caseDecisionContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseDecisionContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisionContents
     * @name CaseDecisionContentsDeleteDelete
     * @request DELETE:/api/CaseDecisionContents/Delete/{id}
     * @secure
     */
    caseDecisionContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseDecisionContent, any>({
        path: `/api/CaseDecisionContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisions
     * @name CaseDecisionsGetAllDynamicList
     * @request GET:/api/CaseDecisions/GetAllDynamic
     * @secure
     */
    caseDecisionsGetAllDynamicList: (
      query: ICaseDecisionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDecisionDynamicResponse, any>({
        path: `/api/CaseDecisions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisions
     * @name CaseDecisionsCreateCreate
     * @request POST:/api/CaseDecisions/Create
     * @secure
     */
    caseDecisionsCreateCreate: (data: ICaseDecision, params: RequestParams = {}) =>
      this.request<ICaseDecision, any>({
        path: `/api/CaseDecisions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisions
     * @name CaseDecisionsPatchPartialUpdate
     * @request PATCH:/api/CaseDecisions/Patch/{id}
     * @secure
     */
    caseDecisionsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CaseDecisions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisions
     * @name CaseDecisionsDeleteDelete
     * @request DELETE:/api/CaseDecisions/Delete/{id}
     * @secure
     */
    caseDecisionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseDecision, any>({
        path: `/api/CaseDecisions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDietaryPreferences
     * @name CaseDietaryPreferencesGetAllDynamicList
     * @request GET:/api/CaseDietaryPreferences/GetAllDynamic
     * @secure
     */
    caseDietaryPreferencesGetAllDynamicList: (
      query: ICaseDietaryPreferencesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDietaryPreferenceDynamicResponse, any>({
        path: `/api/CaseDietaryPreferences/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDietaryPreferences
     * @name CaseDietaryPreferencesCreateCreate
     * @request POST:/api/CaseDietaryPreferences/Create
     * @secure
     */
    caseDietaryPreferencesCreateCreate: (
      data: ICaseDietaryPreference,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDietaryPreference, any>({
        path: `/api/CaseDietaryPreferences/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDietaryPreferences
     * @name CaseDietaryPreferencesPatchPartialUpdate
     * @request PATCH:/api/CaseDietaryPreferences/Patch/{id}
     * @secure
     */
    caseDietaryPreferencesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseDietaryPreferences/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDietaryPreferences
     * @name CaseDietaryPreferencesDeleteDelete
     * @request DELETE:/api/CaseDietaryPreferences/Delete/{id}
     * @secure
     */
    caseDietaryPreferencesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseDietaryPreference, any>({
        path: `/api/CaseDietaryPreferences/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDosageForms
     * @name CaseDosageFormsGetAllDynamicList
     * @request GET:/api/CaseDosageForms/GetAllDynamic
     * @secure
     */
    caseDosageFormsGetAllDynamicList: (
      query: ICaseDosageFormsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDosageFormDynamicResponse, any>({
        path: `/api/CaseDosageForms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDosageForms
     * @name CaseDosageFormsCreateCreate
     * @request POST:/api/CaseDosageForms/Create
     * @secure
     */
    caseDosageFormsCreateCreate: (data: ICaseDosageForm, params: RequestParams = {}) =>
      this.request<ICaseDosageForm, any>({
        path: `/api/CaseDosageForms/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDosageForms
     * @name CaseDosageFormsPatchPartialUpdate
     * @request PATCH:/api/CaseDosageForms/Patch/{id}
     * @secure
     */
    caseDosageFormsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseDosageForms/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDosageForms
     * @name CaseDosageFormsDeleteDelete
     * @request DELETE:/api/CaseDosageForms/Delete/{id}
     * @secure
     */
    caseDosageFormsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseDosageForm, any>({
        path: `/api/CaseDosageForms/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseHealthCareTips
     * @name CaseHealthCareTipsGetAllDynamicList
     * @request GET:/api/CaseHealthCareTips/GetAllDynamic
     * @secure
     */
    caseHealthCareTipsGetAllDynamicList: (
      query: ICaseHealthCareTipsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseHealthCareTipDynamicResponse, any>({
        path: `/api/CaseHealthCareTips/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseHealthCareTips
     * @name CaseHealthCareTipsCreateCreate
     * @request POST:/api/CaseHealthCareTips/Create
     * @secure
     */
    caseHealthCareTipsCreateCreate: (data: ICaseHealthCareTip, params: RequestParams = {}) =>
      this.request<ICaseHealthCareTip, any>({
        path: `/api/CaseHealthCareTips/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseHealthCareTips
     * @name CaseHealthCareTipsPatchPartialUpdate
     * @request PATCH:/api/CaseHealthCareTips/Patch/{id}
     * @secure
     */
    caseHealthCareTipsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseHealthCareTips/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseHealthCareTips
     * @name CaseHealthCareTipsDeleteDelete
     * @request DELETE:/api/CaseHealthCareTips/Delete/{id}
     * @secure
     */
    caseHealthCareTipsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseHealthCareTip, any>({
        path: `/api/CaseHealthCareTips/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroupContents
     * @name CaseMedicalConditionGroupContentsGetAllDynamicList
     * @request GET:/api/CaseMedicalConditionGroupContents/GetAllDynamic
     * @secure
     */
    caseMedicalConditionGroupContentsGetAllDynamicList: (
      query: ICaseMedicalConditionGroupContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionGroupContentDynamicResponse, any>({
        path: `/api/CaseMedicalConditionGroupContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroupContents
     * @name CaseMedicalConditionGroupContentsCreateCreate
     * @request POST:/api/CaseMedicalConditionGroupContents/Create
     * @secure
     */
    caseMedicalConditionGroupContentsCreateCreate: (
      data: ICaseMedicalConditionGroupContent,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionGroupContent, any>({
        path: `/api/CaseMedicalConditionGroupContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroupContents
     * @name CaseMedicalConditionGroupContentsPatchPartialUpdate
     * @request PATCH:/api/CaseMedicalConditionGroupContents/Patch/{id}
     * @secure
     */
    caseMedicalConditionGroupContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseMedicalConditionGroupContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroupContents
     * @name CaseMedicalConditionGroupContentsDeleteDelete
     * @request DELETE:/api/CaseMedicalConditionGroupContents/Delete/{id}
     * @secure
     */
    caseMedicalConditionGroupContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseMedicalConditionGroupContent, any>({
        path: `/api/CaseMedicalConditionGroupContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroups
     * @name CaseMedicalConditionGroupsGetAllDynamicList
     * @request GET:/api/CaseMedicalConditionGroups/GetAllDynamic
     * @secure
     */
    caseMedicalConditionGroupsGetAllDynamicList: (
      query: ICaseMedicalConditionGroupsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionGroupDynamicResponse, any>({
        path: `/api/CaseMedicalConditionGroups/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroups
     * @name CaseMedicalConditionGroupsCreateCreate
     * @request POST:/api/CaseMedicalConditionGroups/Create
     * @secure
     */
    caseMedicalConditionGroupsCreateCreate: (
      data: ICaseMedicalConditionGroup,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionGroup, any>({
        path: `/api/CaseMedicalConditionGroups/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroups
     * @name CaseMedicalConditionGroupsPatchPartialUpdate
     * @request PATCH:/api/CaseMedicalConditionGroups/Patch/{id}
     * @secure
     */
    caseMedicalConditionGroupsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseMedicalConditionGroups/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroups
     * @name CaseMedicalConditionGroupsDeleteDelete
     * @request DELETE:/api/CaseMedicalConditionGroups/Delete/{id}
     * @secure
     */
    caseMedicalConditionGroupsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseMedicalConditionGroup, any>({
        path: `/api/CaseMedicalConditionGroups/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditions
     * @name CaseMedicalConditionsGetAllDynamicList
     * @request GET:/api/CaseMedicalConditions/GetAllDynamic
     * @secure
     */
    caseMedicalConditionsGetAllDynamicList: (
      query: ICaseMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionDynamicResponse, any>({
        path: `/api/CaseMedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditions
     * @name CaseMedicalConditionsCreateCreate
     * @request POST:/api/CaseMedicalConditions/Create
     * @secure
     */
    caseMedicalConditionsCreateCreate: (data: ICaseMedicalCondition, params: RequestParams = {}) =>
      this.request<ICaseMedicalCondition, any>({
        path: `/api/CaseMedicalConditions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditions
     * @name CaseMedicalConditionsPatchPartialUpdate
     * @request PATCH:/api/CaseMedicalConditions/Patch/{id}
     * @secure
     */
    caseMedicalConditionsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseMedicalConditions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditions
     * @name CaseMedicalConditionsDeleteDelete
     * @request DELETE:/api/CaseMedicalConditions/Delete/{id}
     * @secure
     */
    caseMedicalConditionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseMedicalCondition, any>({
        path: `/api/CaseMedicalConditions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedications
     * @name CaseMedicationsGetAllDynamicList
     * @request GET:/api/CaseMedications/GetAllDynamic
     * @secure
     */
    caseMedicationsGetAllDynamicList: (
      query: ICaseMedicationsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicationDynamicResponse, any>({
        path: `/api/CaseMedications/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedications
     * @name CaseMedicationsCreateCreate
     * @request POST:/api/CaseMedications/Create
     * @secure
     */
    caseMedicationsCreateCreate: (data: ICaseMedication, params: RequestParams = {}) =>
      this.request<ICaseMedication, any>({
        path: `/api/CaseMedications/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedications
     * @name CaseMedicationsPatchPartialUpdate
     * @request PATCH:/api/CaseMedications/Patch/{id}
     * @secure
     */
    caseMedicationsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseMedications/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedications
     * @name CaseMedicationsDeleteDelete
     * @request DELETE:/api/CaseMedications/Delete/{id}
     * @secure
     */
    caseMedicationsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseMedication, any>({
        path: `/api/CaseMedications/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageContents
     * @name CasePageContentsGetAllDynamicList
     * @request GET:/api/CasePageContents/GetAllDynamic
     * @secure
     */
    casePageContentsGetAllDynamicList: (
      query: ICasePageContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICasePageContentDynamicResponse, any>({
        path: `/api/CasePageContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageContents
     * @name CasePageContentsCreateCreate
     * @request POST:/api/CasePageContents/Create
     * @secure
     */
    casePageContentsCreateCreate: (data: ICasePageContent, params: RequestParams = {}) =>
      this.request<ICasePageContent, any>({
        path: `/api/CasePageContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageContents
     * @name CasePageContentsPatchPartialUpdate
     * @request PATCH:/api/CasePageContents/Patch/{id}
     * @secure
     */
    casePageContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CasePageContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageContents
     * @name CasePageContentsDeleteDelete
     * @request DELETE:/api/CasePageContents/Delete/{id}
     * @secure
     */
    casePageContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICasePageContent, any>({
        path: `/api/CasePageContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePages
     * @name CasePagesGetAllDynamicList
     * @request GET:/api/CasePages/GetAllDynamic
     * @secure
     */
    casePagesGetAllDynamicList: (
      query: ICasePagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICasePageDynamicResponse, any>({
        path: `/api/CasePages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePages
     * @name CasePagesCreateCreate
     * @request POST:/api/CasePages/Create
     * @secure
     */
    casePagesCreateCreate: (data: ICasePage, params: RequestParams = {}) =>
      this.request<ICasePage, any>({
        path: `/api/CasePages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePages
     * @name CasePagesPatchPartialUpdate
     * @request PATCH:/api/CasePages/Patch/{id}
     * @secure
     */
    casePagesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CasePages/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePages
     * @name CasePagesDeleteDelete
     * @request DELETE:/api/CasePages/Delete/{id}
     * @secure
     */
    casePagesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICasePage, any>({
        path: `/api/CasePages/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageTypes
     * @name CasePageTypesGetAllDynamicList
     * @request GET:/api/CasePageTypes/GetAllDynamic
     * @secure
     */
    casePageTypesGetAllDynamicList: (
      query: ICasePageTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICasePageTypeDynamicResponse, any>({
        path: `/api/CasePageTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageTypes
     * @name CasePageTypesCreateCreate
     * @request POST:/api/CasePageTypes/Create
     * @secure
     */
    casePageTypesCreateCreate: (data: ICasePageType, params: RequestParams = {}) =>
      this.request<ICasePageType, any>({
        path: `/api/CasePageTypes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageTypes
     * @name CasePageTypesPatchPartialUpdate
     * @request PATCH:/api/CasePageTypes/Patch/{id}
     * @secure
     */
    casePageTypesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CasePageTypes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageTypes
     * @name CasePageTypesDeleteDelete
     * @request DELETE:/api/CasePageTypes/Delete/{id}
     * @secure
     */
    casePageTypesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICasePageType, any>({
        path: `/api/CasePageTypes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases
     * @name CasesGetAllDynamicList
     * @request GET:/api/Cases/GetAllDynamic
     * @secure
     */
    casesGetAllDynamicList: (query: ICasesGetAllDynamicListParams, params: RequestParams = {}) =>
      this.request<ICaseDynamicResponse, any>({
        path: `/api/Cases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases
     * @name CasesCreateCreate
     * @request POST:/api/Cases/Create
     * @secure
     */
    casesCreateCreate: (data: ICase, params: RequestParams = {}) =>
      this.request<ICase, any>({
        path: `/api/Cases/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases
     * @name CasesPatchPartialUpdate
     * @request PATCH:/api/Cases/Patch/{id}
     * @secure
     */
    casesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Cases/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases
     * @name CasesDeleteDelete
     * @request DELETE:/api/Cases/Delete/{id}
     * @secure
     */
    casesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICase, any>({
        path: `/api/Cases/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases
     * @name CasesCloneCreate
     * @request POST:/api/Cases/Clone
     * @secure
     */
    casesCloneCreate: (data: ICloneCaseRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Cases/Clone`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases
     * @name CasesGetContentList
     * @request GET:/api/Cases/GetContent
     * @secure
     */
    casesGetContentList: (query: ICasesGetContentListParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Cases/GetContent`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSensitivities
     * @name CaseSensitivitiesGetAllDynamicList
     * @request GET:/api/CaseSensitivities/GetAllDynamic
     * @secure
     */
    caseSensitivitiesGetAllDynamicList: (
      query: ICaseSensitivitiesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseSensitivityDynamicResponse, any>({
        path: `/api/CaseSensitivities/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSensitivities
     * @name CaseSensitivitiesCreateCreate
     * @request POST:/api/CaseSensitivities/Create
     * @secure
     */
    caseSensitivitiesCreateCreate: (data: ICaseSensitivity, params: RequestParams = {}) =>
      this.request<ICaseSensitivity, any>({
        path: `/api/CaseSensitivities/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSensitivities
     * @name CaseSensitivitiesPatchPartialUpdate
     * @request PATCH:/api/CaseSensitivities/Patch/{id}
     * @secure
     */
    caseSensitivitiesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CaseSensitivities/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSensitivities
     * @name CaseSensitivitiesDeleteDelete
     * @request DELETE:/api/CaseSensitivities/Delete/{id}
     * @secure
     */
    caseSensitivitiesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseSensitivity, any>({
        path: `/api/CaseSensitivities/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSymptoms
     * @name CaseSymptomsGetAllDynamicList
     * @request GET:/api/CaseSymptoms/GetAllDynamic
     * @secure
     */
    caseSymptomsGetAllDynamicList: (
      query: ICaseSymptomsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseSymptomDynamicResponse, any>({
        path: `/api/CaseSymptoms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSymptoms
     * @name CaseSymptomsCreateCreate
     * @request POST:/api/CaseSymptoms/Create
     * @secure
     */
    caseSymptomsCreateCreate: (data: ICaseSymptom, params: RequestParams = {}) =>
      this.request<ICaseSymptom, any>({
        path: `/api/CaseSymptoms/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSymptoms
     * @name CaseSymptomsPatchPartialUpdate
     * @request PATCH:/api/CaseSymptoms/Patch/{id}
     * @secure
     */
    caseSymptomsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CaseSymptoms/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSymptoms
     * @name CaseSymptomsDeleteDelete
     * @request DELETE:/api/CaseSymptoms/Delete/{id}
     * @secure
     */
    caseSymptomsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseSymptom, any>({
        path: `/api/CaseSymptoms/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseTags
     * @name CaseTagsGetAllDynamicList
     * @request GET:/api/CaseTags/GetAllDynamic
     * @secure
     */
    caseTagsGetAllDynamicList: (
      query: ICaseTagsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseTagDynamicResponse, any>({
        path: `/api/CaseTags/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseTags
     * @name CaseTagsCreateCreate
     * @request POST:/api/CaseTags/Create
     * @secure
     */
    caseTagsCreateCreate: (data: ICaseTag, params: RequestParams = {}) =>
      this.request<ICaseTag, any>({
        path: `/api/CaseTags/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseTags
     * @name CaseTagsPatchPartialUpdate
     * @request PATCH:/api/CaseTags/Patch/{id}
     * @secure
     */
    caseTagsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CaseTags/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseTags
     * @name CaseTagsDeleteDelete
     * @request DELETE:/api/CaseTags/Delete/{id}
     * @secure
     */
    caseTagsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICaseTag, any>({
        path: `/api/CaseTags/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Countries
     * @name CountriesGetAllDynamicList
     * @request GET:/api/Countries/GetAllDynamic
     * @secure
     */
    countriesGetAllDynamicList: (
      query: ICountriesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICountryDynamicResponse, any>({
        path: `/api/Countries/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Countries
     * @name CountriesCreateCreate
     * @request POST:/api/Countries/Create
     * @secure
     */
    countriesCreateCreate: (data: ICountry, params: RequestParams = {}) =>
      this.request<ICountry, any>({
        path: `/api/Countries/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Countries
     * @name CountriesPatchPartialUpdate
     * @request PATCH:/api/Countries/Patch/{id}
     * @secure
     */
    countriesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Countries/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Countries
     * @name CountriesDeleteDelete
     * @request DELETE:/api/Countries/Delete/{id}
     * @secure
     */
    countriesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICountry, any>({
        path: `/api/Countries/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryLanguages
     * @name CountryLanguagesGetAllDynamicList
     * @request GET:/api/CountryLanguages/GetAllDynamic
     * @secure
     */
    countryLanguagesGetAllDynamicList: (
      query: ICountryLanguagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICountryLanguageDynamicResponse, any>({
        path: `/api/CountryLanguages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryLanguages
     * @name CountryLanguagesCreateCreate
     * @request POST:/api/CountryLanguages/Create
     * @secure
     */
    countryLanguagesCreateCreate: (data: ICountryLanguage, params: RequestParams = {}) =>
      this.request<ICountryLanguage, any>({
        path: `/api/CountryLanguages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryLanguages
     * @name CountryLanguagesPatchPartialUpdate
     * @request PATCH:/api/CountryLanguages/Patch/{id}
     * @secure
     */
    countryLanguagesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/CountryLanguages/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryLanguages
     * @name CountryLanguagesDeleteDelete
     * @request DELETE:/api/CountryLanguages/Delete/{id}
     * @secure
     */
    countryLanguagesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ICountryLanguage, any>({
        path: `/api/CountryLanguages/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryLanguages
     * @name CountryLanguagesCreateBulkCreate
     * @request POST:/api/CountryLanguages/CreateBulk
     * @secure
     */
    countryLanguagesCreateBulkCreate: (data: IRelationshipBulk, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CountryLanguages/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferenceContents
     * @name DietaryPreferenceContentsGetAllDynamicList
     * @request GET:/api/DietaryPreferenceContents/GetAllDynamic
     * @secure
     */
    dietaryPreferenceContentsGetAllDynamicList: (
      query: IDietaryPreferenceContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDietaryPreferenceContentDynamicResponse, any>({
        path: `/api/DietaryPreferenceContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferenceContents
     * @name DietaryPreferenceContentsCreateCreate
     * @request POST:/api/DietaryPreferenceContents/Create
     * @secure
     */
    dietaryPreferenceContentsCreateCreate: (
      data: IDietaryPreferenceContent,
      params: RequestParams = {},
    ) =>
      this.request<IDietaryPreferenceContent, any>({
        path: `/api/DietaryPreferenceContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferenceContents
     * @name DietaryPreferenceContentsPatchPartialUpdate
     * @request PATCH:/api/DietaryPreferenceContents/Patch/{id}
     * @secure
     */
    dietaryPreferenceContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/DietaryPreferenceContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferenceContents
     * @name DietaryPreferenceContentsDeleteDelete
     * @request DELETE:/api/DietaryPreferenceContents/Delete/{id}
     * @secure
     */
    dietaryPreferenceContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDietaryPreferenceContent, any>({
        path: `/api/DietaryPreferenceContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferences
     * @name DietaryPreferencesGetAllDynamicList
     * @request GET:/api/DietaryPreferences/GetAllDynamic
     * @secure
     */
    dietaryPreferencesGetAllDynamicList: (
      query: IDietaryPreferencesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDietaryPreferenceDynamicResponse, any>({
        path: `/api/DietaryPreferences/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferences
     * @name DietaryPreferencesCreateCreate
     * @request POST:/api/DietaryPreferences/Create
     * @secure
     */
    dietaryPreferencesCreateCreate: (data: IDietaryPreference, params: RequestParams = {}) =>
      this.request<IDietaryPreference, any>({
        path: `/api/DietaryPreferences/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferences
     * @name DietaryPreferencesPatchPartialUpdate
     * @request PATCH:/api/DietaryPreferences/Patch/{id}
     * @secure
     */
    dietaryPreferencesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/DietaryPreferences/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DietaryPreferences
     * @name DietaryPreferencesDeleteDelete
     * @request DELETE:/api/DietaryPreferences/Delete/{id}
     * @secure
     */
    dietaryPreferencesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDietaryPreference, any>({
        path: `/api/DietaryPreferences/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategories
     * @name DosageFormCategoriesGetAllDynamicList
     * @request GET:/api/DosageFormCategories/GetAllDynamic
     * @secure
     */
    dosageFormCategoriesGetAllDynamicList: (
      query: IDosageFormCategoriesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormCategoryDynamicResponse, any>({
        path: `/api/DosageFormCategories/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategories
     * @name DosageFormCategoriesCreateCreate
     * @request POST:/api/DosageFormCategories/Create
     * @secure
     */
    dosageFormCategoriesCreateCreate: (data: IDosageFormCategory, params: RequestParams = {}) =>
      this.request<IDosageFormCategory, any>({
        path: `/api/DosageFormCategories/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategories
     * @name DosageFormCategoriesPatchPartialUpdate
     * @request PATCH:/api/DosageFormCategories/Patch/{id}
     * @secure
     */
    dosageFormCategoriesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/DosageFormCategories/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategories
     * @name DosageFormCategoriesDeleteDelete
     * @request DELETE:/api/DosageFormCategories/Delete/{id}
     * @secure
     */
    dosageFormCategoriesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDosageFormCategory, any>({
        path: `/api/DosageFormCategories/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategoryContents
     * @name DosageFormCategoryContentsGetAllDynamicList
     * @request GET:/api/DosageFormCategoryContents/GetAllDynamic
     * @secure
     */
    dosageFormCategoryContentsGetAllDynamicList: (
      query: IDosageFormCategoryContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormCategoryContentDynamicResponse, any>({
        path: `/api/DosageFormCategoryContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategoryContents
     * @name DosageFormCategoryContentsCreateCreate
     * @request POST:/api/DosageFormCategoryContents/Create
     * @secure
     */
    dosageFormCategoryContentsCreateCreate: (
      data: IDosageFormCategoryContent,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormCategoryContent, any>({
        path: `/api/DosageFormCategoryContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategoryContents
     * @name DosageFormCategoryContentsPatchPartialUpdate
     * @request PATCH:/api/DosageFormCategoryContents/Patch/{id}
     * @secure
     */
    dosageFormCategoryContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/DosageFormCategoryContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategoryContents
     * @name DosageFormCategoryContentsDeleteDelete
     * @request DELETE:/api/DosageFormCategoryContents/Delete/{id}
     * @secure
     */
    dosageFormCategoryContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDosageFormCategoryContent, any>({
        path: `/api/DosageFormCategoryContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormContents
     * @name DosageFormContentsGetAllDynamicList
     * @request GET:/api/DosageFormContents/GetAllDynamic
     * @secure
     */
    dosageFormContentsGetAllDynamicList: (
      query: IDosageFormContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormContentDynamicResponse, any>({
        path: `/api/DosageFormContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormContents
     * @name DosageFormContentsCreateCreate
     * @request POST:/api/DosageFormContents/Create
     * @secure
     */
    dosageFormContentsCreateCreate: (data: IDosageFormContent, params: RequestParams = {}) =>
      this.request<IDosageFormContent, any>({
        path: `/api/DosageFormContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormContents
     * @name DosageFormContentsPatchPartialUpdate
     * @request PATCH:/api/DosageFormContents/Patch/{id}
     * @secure
     */
    dosageFormContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/DosageFormContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormContents
     * @name DosageFormContentsDeleteDelete
     * @request DELETE:/api/DosageFormContents/Delete/{id}
     * @secure
     */
    dosageFormContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDosageFormContent, any>({
        path: `/api/DosageFormContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageForms
     * @name DosageFormsGetAllDynamicList
     * @request GET:/api/DosageForms/GetAllDynamic
     * @secure
     */
    dosageFormsGetAllDynamicList: (
      query: IDosageFormsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormDynamicResponse, any>({
        path: `/api/DosageForms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageForms
     * @name DosageFormsCreateCreate
     * @request POST:/api/DosageForms/Create
     * @secure
     */
    dosageFormsCreateCreate: (data: IDosageForm, params: RequestParams = {}) =>
      this.request<IDosageForm, any>({
        path: `/api/DosageForms/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageForms
     * @name DosageFormsPatchPartialUpdate
     * @request PATCH:/api/DosageForms/Patch/{id}
     * @secure
     */
    dosageFormsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/DosageForms/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageForms
     * @name DosageFormsDeleteDelete
     * @request DELETE:/api/DosageForms/Delete/{id}
     * @secure
     */
    dosageFormsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDosageForm, any>({
        path: `/api/DosageForms/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypeContents
     * @name DosageFormUnitTypeContentsGetAllDynamicList
     * @request GET:/api/DosageFormUnitTypeContents/GetAllDynamic
     * @secure
     */
    dosageFormUnitTypeContentsGetAllDynamicList: (
      query: IDosageFormUnitTypeContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormUnitTypeContentDynamicResponse, any>({
        path: `/api/DosageFormUnitTypeContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypeContents
     * @name DosageFormUnitTypeContentsCreateCreate
     * @request POST:/api/DosageFormUnitTypeContents/Create
     * @secure
     */
    dosageFormUnitTypeContentsCreateCreate: (
      data: IDosageFormUnitTypeContent,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormUnitTypeContent, any>({
        path: `/api/DosageFormUnitTypeContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypeContents
     * @name DosageFormUnitTypeContentsPatchPartialUpdate
     * @request PATCH:/api/DosageFormUnitTypeContents/Patch/{id}
     * @secure
     */
    dosageFormUnitTypeContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/DosageFormUnitTypeContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypeContents
     * @name DosageFormUnitTypeContentsDeleteDelete
     * @request DELETE:/api/DosageFormUnitTypeContents/Delete/{id}
     * @secure
     */
    dosageFormUnitTypeContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDosageFormUnitTypeContent, any>({
        path: `/api/DosageFormUnitTypeContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypes
     * @name DosageFormUnitTypesGetAllDynamicList
     * @request GET:/api/DosageFormUnitTypes/GetAllDynamic
     * @secure
     */
    dosageFormUnitTypesGetAllDynamicList: (
      query: IDosageFormUnitTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormUnitTypeDynamicResponse, any>({
        path: `/api/DosageFormUnitTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypes
     * @name DosageFormUnitTypesCreateCreate
     * @request POST:/api/DosageFormUnitTypes/Create
     * @secure
     */
    dosageFormUnitTypesCreateCreate: (data: IDosageFormUnitType, params: RequestParams = {}) =>
      this.request<IDosageFormUnitType, any>({
        path: `/api/DosageFormUnitTypes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypes
     * @name DosageFormUnitTypesPatchPartialUpdate
     * @request PATCH:/api/DosageFormUnitTypes/Patch/{id}
     * @secure
     */
    dosageFormUnitTypesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/DosageFormUnitTypes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypes
     * @name DosageFormUnitTypesDeleteDelete
     * @request DELETE:/api/DosageFormUnitTypes/Delete/{id}
     * @secure
     */
    dosageFormUnitTypesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDosageFormUnitType, any>({
        path: `/api/DosageFormUnitTypes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GenderContents
     * @name GenderContentsGetAllDynamicList
     * @request GET:/api/GenderContents/GetAllDynamic
     * @secure
     */
    genderContentsGetAllDynamicList: (
      query: IGenderContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IGenderContentDynamicResponse, any>({
        path: `/api/GenderContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GenderContents
     * @name GenderContentsCreateCreate
     * @request POST:/api/GenderContents/Create
     * @secure
     */
    genderContentsCreateCreate: (data: IGenderContent, params: RequestParams = {}) =>
      this.request<IGenderContent, any>({
        path: `/api/GenderContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GenderContents
     * @name GenderContentsPatchPartialUpdate
     * @request PATCH:/api/GenderContents/Patch/{id}
     * @secure
     */
    genderContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/GenderContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GenderContents
     * @name GenderContentsDeleteDelete
     * @request DELETE:/api/GenderContents/Delete/{id}
     * @secure
     */
    genderContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IGenderContent, any>({
        path: `/api/GenderContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Genders
     * @name GendersGetAllDynamicList
     * @request GET:/api/Genders/GetAllDynamic
     * @secure
     */
    gendersGetAllDynamicList: (
      query: IGendersGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IGenderDynamicResponse, any>({
        path: `/api/Genders/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Genders
     * @name GendersCreateCreate
     * @request POST:/api/Genders/Create
     * @secure
     */
    gendersCreateCreate: (data: IGender, params: RequestParams = {}) =>
      this.request<IGender, any>({
        path: `/api/Genders/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Genders
     * @name GendersPatchPartialUpdate
     * @request PATCH:/api/Genders/Patch/{id}
     * @secure
     */
    gendersPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Genders/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Genders
     * @name GendersDeleteDelete
     * @request DELETE:/api/Genders/Delete/{id}
     * @secure
     */
    gendersDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IGender, any>({
        path: `/api/Genders/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersGetAllDynamicList
     * @request GET:/api/GlobalParameters/GetAllDynamic
     * @secure
     */
    globalParametersGetAllDynamicList: (
      query: IGlobalParametersGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IGlobalParameterDynamicResponse, any>({
        path: `/api/GlobalParameters/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersCreateCreate
     * @request POST:/api/GlobalParameters/Create
     * @secure
     */
    globalParametersCreateCreate: (data: IGlobalParameter, params: RequestParams = {}) =>
      this.request<IGlobalParameter, any>({
        path: `/api/GlobalParameters/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersPatchPartialUpdate
     * @request PATCH:/api/GlobalParameters/Patch/{id}
     * @secure
     */
    globalParametersPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/GlobalParameters/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersDeleteDelete
     * @request DELETE:/api/GlobalParameters/Delete/{id}
     * @secure
     */
    globalParametersDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IGlobalParameter, any>({
        path: `/api/GlobalParameters/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTipContents
     * @name HealthCareTipContentsGetAllDynamicList
     * @request GET:/api/HealthCareTipContents/GetAllDynamic
     * @secure
     */
    healthCareTipContentsGetAllDynamicList: (
      query: IHealthCareTipContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IHealthCareTipContentDynamicResponse, any>({
        path: `/api/HealthCareTipContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTipContents
     * @name HealthCareTipContentsCreateCreate
     * @request POST:/api/HealthCareTipContents/Create
     * @secure
     */
    healthCareTipContentsCreateCreate: (data: IHealthCareTipContent, params: RequestParams = {}) =>
      this.request<IHealthCareTipContent, any>({
        path: `/api/HealthCareTipContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTipContents
     * @name HealthCareTipContentsPatchPartialUpdate
     * @request PATCH:/api/HealthCareTipContents/Patch/{id}
     * @secure
     */
    healthCareTipContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/HealthCareTipContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTipContents
     * @name HealthCareTipContentsDeleteDelete
     * @request DELETE:/api/HealthCareTipContents/Delete/{id}
     * @secure
     */
    healthCareTipContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IHealthCareTipContent, any>({
        path: `/api/HealthCareTipContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTips
     * @name HealthCareTipsGetAllDynamicList
     * @request GET:/api/HealthCareTips/GetAllDynamic
     * @secure
     */
    healthCareTipsGetAllDynamicList: (
      query: IHealthCareTipsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IHealthCareTipDynamicResponse, any>({
        path: `/api/HealthCareTips/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTips
     * @name HealthCareTipsCreateCreate
     * @request POST:/api/HealthCareTips/Create
     * @secure
     */
    healthCareTipsCreateCreate: (data: IHealthCareTip, params: RequestParams = {}) =>
      this.request<IHealthCareTip, any>({
        path: `/api/HealthCareTips/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTips
     * @name HealthCareTipsPatchPartialUpdate
     * @request PATCH:/api/HealthCareTips/Patch/{id}
     * @secure
     */
    healthCareTipsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/HealthCareTips/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCareTips
     * @name HealthCareTipsDeleteDelete
     * @request DELETE:/api/HealthCareTips/Delete/{id}
     * @secure
     */
    healthCareTipsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IHealthCareTip, any>({
        path: `/api/HealthCareTips/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsDeleteDelete
     * @request DELETE:/api/Labels/Delete/{id}
     * @secure
     */
    labelsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILabel, any>({
        path: `/api/Labels/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsGetAllDynamicList
     * @request GET:/api/Labels/GetAllDynamic
     * @secure
     */
    labelsGetAllDynamicList: (query: ILabelsGetAllDynamicListParams, params: RequestParams = {}) =>
      this.request<ILabelDynamicResponse, any>({
        path: `/api/Labels/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsCreateCreate
     * @request POST:/api/Labels/Create
     * @secure
     */
    labelsCreateCreate: (data: ILabel, params: RequestParams = {}) =>
      this.request<ILabel, any>({
        path: `/api/Labels/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsSyncDetail
     * @request GET:/api/Labels/Sync/{id}
     * @secure
     */
    labelsSyncDetail: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Labels/Sync/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsPatchPartialUpdate
     * @request PATCH:/api/Labels/Patch/{id}
     * @secure
     */
    labelsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Labels/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesDeleteDelete
     * @request DELETE:/api/LabelSources/Delete/{id}
     * @secure
     */
    labelSourcesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILabelSource, any>({
        path: `/api/LabelSources/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesGetAllDynamicList
     * @request GET:/api/LabelSources/GetAllDynamic
     * @secure
     */
    labelSourcesGetAllDynamicList: (
      query: ILabelSourcesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILabelSourceDynamicResponse, any>({
        path: `/api/LabelSources/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesCreateCreate
     * @request POST:/api/LabelSources/Create
     * @secure
     */
    labelSourcesCreateCreate: (data: ILabelSource, params: RequestParams = {}) =>
      this.request<ILabelSource, any>({
        path: `/api/LabelSources/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesPatchPartialUpdate
     * @request PATCH:/api/LabelSources/Patch/{id}
     * @secure
     */
    labelSourcesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/LabelSources/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesDeleteDelete
     * @request DELETE:/api/Languages/Delete/{id}
     * @secure
     */
    languagesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILanguage, any>({
        path: `/api/Languages/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetDefaultLanguageList
     * @request GET:/api/Languages/GetDefaultLanguage
     * @secure
     */
    languagesGetDefaultLanguageList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Languages/GetDefaultLanguage`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetAllDynamicList
     * @request GET:/api/Languages/GetAllDynamic
     * @secure
     */
    languagesGetAllDynamicList: (
      query: ILanguagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILanguageDynamicResponse, any>({
        path: `/api/Languages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesCreateCreate
     * @request POST:/api/Languages/Create
     * @secure
     */
    languagesCreateCreate: (data: ILanguage, params: RequestParams = {}) =>
      this.request<ILanguage, any>({
        path: `/api/Languages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesPatchPartialUpdate
     * @request PATCH:/api/Languages/Patch/{id}
     * @secure
     */
    languagesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Languages/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaUploads
     * @name MediaUploadsUploadFileToCloudCreate
     * @request POST:/api/MediaUploads/UploadFileToCloud
     * @secure
     */
    mediaUploadsUploadFileToCloudCreate: (data: ICloudFileViewModel, params: RequestParams = {}) =>
      this.request<ICloudFileViewModel, any>({
        path: `/api/MediaUploads/UploadFileToCloud`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaUploads
     * @name MediaUploadsRemoveFileFromCloudUpdate
     * @request PUT:/api/MediaUploads/RemoveFileFromCloud
     * @secure
     */
    mediaUploadsRemoveFileFromCloudUpdate: (
      query: IMediaUploadsRemoveFileFromCloudUpdateParams,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MediaUploads/RemoveFileFromCloud`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionContents
     * @name MedicalConditionContentsGetAllDynamicList
     * @request GET:/api/MedicalConditionContents/GetAllDynamic
     * @secure
     */
    medicalConditionContentsGetAllDynamicList: (
      query: IMedicalConditionContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionContentDynamicResponse, any>({
        path: `/api/MedicalConditionContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionContents
     * @name MedicalConditionContentsCreateCreate
     * @request POST:/api/MedicalConditionContents/Create
     * @secure
     */
    medicalConditionContentsCreateCreate: (
      data: IMedicalConditionContent,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionContent, any>({
        path: `/api/MedicalConditionContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionContents
     * @name MedicalConditionContentsPatchPartialUpdate
     * @request PATCH:/api/MedicalConditionContents/Patch/{id}
     * @secure
     */
    medicalConditionContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MedicalConditionContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionContents
     * @name MedicalConditionContentsDeleteDelete
     * @request DELETE:/api/MedicalConditionContents/Delete/{id}
     * @secure
     */
    medicalConditionContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMedicalConditionContent, any>({
        path: `/api/MedicalConditionContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredientContents
     * @name MedicalConditionIngredientContentsGetAllDynamicList
     * @request GET:/api/MedicalConditionIngredientContents/GetAllDynamic
     * @secure
     */
    medicalConditionIngredientContentsGetAllDynamicList: (
      query: IMedicalConditionIngredientContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionIngredientContentDynamicResponse, any>({
        path: `/api/MedicalConditionIngredientContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredientContents
     * @name MedicalConditionIngredientContentsCreateCreate
     * @request POST:/api/MedicalConditionIngredientContents/Create
     * @secure
     */
    medicalConditionIngredientContentsCreateCreate: (
      data: IMedicalConditionIngredientContent,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionIngredientContent, any>({
        path: `/api/MedicalConditionIngredientContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredientContents
     * @name MedicalConditionIngredientContentsPatchPartialUpdate
     * @request PATCH:/api/MedicalConditionIngredientContents/Patch/{id}
     * @secure
     */
    medicalConditionIngredientContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MedicalConditionIngredientContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredientContents
     * @name MedicalConditionIngredientContentsDeleteDelete
     * @request DELETE:/api/MedicalConditionIngredientContents/Delete/{id}
     * @secure
     */
    medicalConditionIngredientContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMedicalConditionIngredientContent, any>({
        path: `/api/MedicalConditionIngredientContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredients
     * @name MedicalConditionIngredientsGetAllDynamicList
     * @request GET:/api/MedicalConditionIngredients/GetAllDynamic
     * @secure
     */
    medicalConditionIngredientsGetAllDynamicList: (
      query: IMedicalConditionIngredientsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionIngredientDynamicResponse, any>({
        path: `/api/MedicalConditionIngredients/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredients
     * @name MedicalConditionIngredientsCreateCreate
     * @request POST:/api/MedicalConditionIngredients/Create
     * @secure
     */
    medicalConditionIngredientsCreateCreate: (
      data: IMedicalConditionIngredient,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionIngredient, any>({
        path: `/api/MedicalConditionIngredients/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredients
     * @name MedicalConditionIngredientsPatchPartialUpdate
     * @request PATCH:/api/MedicalConditionIngredients/Patch/{id}
     * @secure
     */
    medicalConditionIngredientsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MedicalConditionIngredients/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredients
     * @name MedicalConditionIngredientsDeleteDelete
     * @request DELETE:/api/MedicalConditionIngredients/Delete/{id}
     * @secure
     */
    medicalConditionIngredientsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMedicalConditionIngredient, any>({
        path: `/api/MedicalConditionIngredients/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditions
     * @name MedicalConditionsGetAllDynamicList
     * @request GET:/api/MedicalConditions/GetAllDynamic
     * @secure
     */
    medicalConditionsGetAllDynamicList: (
      query: IMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionDynamicResponse, any>({
        path: `/api/MedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditions
     * @name MedicalConditionsCreateCreate
     * @request POST:/api/MedicalConditions/Create
     * @secure
     */
    medicalConditionsCreateCreate: (data: IMedicalCondition, params: RequestParams = {}) =>
      this.request<IMedicalCondition, any>({
        path: `/api/MedicalConditions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditions
     * @name MedicalConditionsPatchPartialUpdate
     * @request PATCH:/api/MedicalConditions/Patch/{id}
     * @secure
     */
    medicalConditionsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MedicalConditions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditions
     * @name MedicalConditionsDeleteDelete
     * @request DELETE:/api/MedicalConditions/Delete/{id}
     * @secure
     */
    medicalConditionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMedicalCondition, any>({
        path: `/api/MedicalConditions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicationContents
     * @name MedicationContentsGetAllDynamicList
     * @request GET:/api/MedicationContents/GetAllDynamic
     * @secure
     */
    medicationContentsGetAllDynamicList: (
      query: IMedicationContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicationContentDynamicResponse, any>({
        path: `/api/MedicationContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicationContents
     * @name MedicationContentsCreateCreate
     * @request POST:/api/MedicationContents/Create
     * @secure
     */
    medicationContentsCreateCreate: (data: IMedicationContent, params: RequestParams = {}) =>
      this.request<IMedicationContent, any>({
        path: `/api/MedicationContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicationContents
     * @name MedicationContentsPatchPartialUpdate
     * @request PATCH:/api/MedicationContents/Patch/{id}
     * @secure
     */
    medicationContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MedicationContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicationContents
     * @name MedicationContentsDeleteDelete
     * @request DELETE:/api/MedicationContents/Delete/{id}
     * @secure
     */
    medicationContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMedicationContent, any>({
        path: `/api/MedicationContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Medications
     * @name MedicationsGetAllDynamicList
     * @request GET:/api/Medications/GetAllDynamic
     * @secure
     */
    medicationsGetAllDynamicList: (
      query: IMedicationsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicationDynamicResponse, any>({
        path: `/api/Medications/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Medications
     * @name MedicationsCreateCreate
     * @request POST:/api/Medications/Create
     * @secure
     */
    medicationsCreateCreate: (data: IMedication, params: RequestParams = {}) =>
      this.request<IMedication, any>({
        path: `/api/Medications/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Medications
     * @name MedicationsPatchPartialUpdate
     * @request PATCH:/api/Medications/Patch/{id}
     * @secure
     */
    medicationsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Medications/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Medications
     * @name MedicationsDeleteDelete
     * @request DELETE:/api/Medications/Delete/{id}
     * @secure
     */
    medicationsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMedication, any>({
        path: `/api/Medications/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderStatuses
     * @name OrderStatusesGetAllDynamicList
     * @request GET:/api/OrderStatuses/GetAllDynamic
     * @secure
     */
    orderStatusesGetAllDynamicList: (
      query: IOrderStatusesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IOrderStatusDynamicResponse, any>({
        path: `/api/OrderStatuses/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderStatuses
     * @name OrderStatusesCreateCreate
     * @request POST:/api/OrderStatuses/Create
     * @secure
     */
    orderStatusesCreateCreate: (data: IOrderStatus, params: RequestParams = {}) =>
      this.request<IOrderStatus, any>({
        path: `/api/OrderStatuses/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderStatuses
     * @name OrderStatusesPatchPartialUpdate
     * @request PATCH:/api/OrderStatuses/Patch/{id}
     * @secure
     */
    orderStatusesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/OrderStatuses/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderStatuses
     * @name OrderStatusesDeleteDelete
     * @request DELETE:/api/OrderStatuses/Delete/{id}
     * @secure
     */
    orderStatusesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IOrderStatus, any>({
        path: `/api/OrderStatuses/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanies
     * @name PharmaCompaniesGetAllDynamicList
     * @request GET:/api/PharmaCompanies/GetAllDynamic
     * @secure
     */
    pharmaCompaniesGetAllDynamicList: (
      query: IPharmaCompaniesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyDynamicResponse, any>({
        path: `/api/PharmaCompanies/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanies
     * @name PharmaCompaniesCreateCreate
     * @request POST:/api/PharmaCompanies/Create
     * @secure
     */
    pharmaCompaniesCreateCreate: (data: IPharmaCompany, params: RequestParams = {}) =>
      this.request<IPharmaCompany, any>({
        path: `/api/PharmaCompanies/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanies
     * @name PharmaCompaniesPatchPartialUpdate
     * @request PATCH:/api/PharmaCompanies/Patch/{id}
     * @secure
     */
    pharmaCompaniesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanies/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanies
     * @name PharmaCompaniesDeleteDelete
     * @request DELETE:/api/PharmaCompanies/Delete/{id}
     * @secure
     */
    pharmaCompaniesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IPharmaCompany, any>({
        path: `/api/PharmaCompanies/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsGetAllDynamicList
     * @request GET:/api/PharmaCompanyOrderDetails/GetAllDynamic
     * @secure
     */
    pharmaCompanyOrderDetailsGetAllDynamicList: (
      query: IPharmaCompanyOrderDetailsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderDetailDynamicResponse, any>({
        path: `/api/PharmaCompanyOrderDetails/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsCreateCreate
     * @request POST:/api/PharmaCompanyOrderDetails/Create
     * @secure
     */
    pharmaCompanyOrderDetailsCreateCreate: (
      data: IPharmaCompanyOrderDetail,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderDetail, any>({
        path: `/api/PharmaCompanyOrderDetails/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsPatchPartialUpdate
     * @request PATCH:/api/PharmaCompanyOrderDetails/Patch/{id}
     * @secure
     */
    pharmaCompanyOrderDetailsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrderDetails/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsDeleteDelete
     * @request DELETE:/api/PharmaCompanyOrderDetails/Delete/{id}
     * @secure
     */
    pharmaCompanyOrderDetailsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrderDetail, any>({
        path: `/api/PharmaCompanyOrderDetails/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersGetAllDynamicList
     * @request GET:/api/PharmaCompanyOrders/GetAllDynamic
     * @secure
     */
    pharmaCompanyOrdersGetAllDynamicList: (
      query: IPharmaCompanyOrdersGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderDynamicResponse, any>({
        path: `/api/PharmaCompanyOrders/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersCreateCreate
     * @request POST:/api/PharmaCompanyOrders/Create
     * @secure
     */
    pharmaCompanyOrdersCreateCreate: (data: IPharmaCompanyOrder, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrder, any>({
        path: `/api/PharmaCompanyOrders/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersPatchPartialUpdate
     * @request PATCH:/api/PharmaCompanyOrders/Patch/{id}
     * @secure
     */
    pharmaCompanyOrdersPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrders/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersDeleteDelete
     * @request DELETE:/api/PharmaCompanyOrders/Delete/{id}
     * @secure
     */
    pharmaCompanyOrdersDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrder, any>({
        path: `/api/PharmaCompanyOrders/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsGetAllDynamicList
     * @request GET:/api/PharmaCompanyOrderTenants/GetAllDynamic
     * @secure
     */
    pharmaCompanyOrderTenantsGetAllDynamicList: (
      query: IPharmaCompanyOrderTenantsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderTenantDynamicResponse, any>({
        path: `/api/PharmaCompanyOrderTenants/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsCreateCreate
     * @request POST:/api/PharmaCompanyOrderTenants/Create
     * @secure
     */
    pharmaCompanyOrderTenantsCreateCreate: (
      data: IPharmaCompanyOrderTenant,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderTenant, any>({
        path: `/api/PharmaCompanyOrderTenants/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsPatchPartialUpdate
     * @request PATCH:/api/PharmaCompanyOrderTenants/Patch/{id}
     * @secure
     */
    pharmaCompanyOrderTenantsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrderTenants/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsDeleteDelete
     * @request DELETE:/api/PharmaCompanyOrderTenants/Delete/{id}
     * @secure
     */
    pharmaCompanyOrderTenantsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrderTenant, any>({
        path: `/api/PharmaCompanyOrderTenants/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsCreateBulkCreate
     * @request POST:/api/PharmaCompanyOrderTenants/CreateBulk
     * @secure
     */
    pharmaCompanyOrderTenantsCreateBulkCreate: (
      data: IRelationshipBulk,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrderTenants/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrandContents
     * @name ProductBrandContentsGetAllDynamicList
     * @request GET:/api/ProductBrandContents/GetAllDynamic
     * @secure
     */
    productBrandContentsGetAllDynamicList: (
      query: IProductBrandContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductBrandContentDynamicResponse, any>({
        path: `/api/ProductBrandContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrandContents
     * @name ProductBrandContentsCreateCreate
     * @request POST:/api/ProductBrandContents/Create
     * @secure
     */
    productBrandContentsCreateCreate: (data: IProductBrandContent, params: RequestParams = {}) =>
      this.request<IProductBrandContent, any>({
        path: `/api/ProductBrandContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrandContents
     * @name ProductBrandContentsPatchPartialUpdate
     * @request PATCH:/api/ProductBrandContents/Patch/{id}
     * @secure
     */
    productBrandContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductBrandContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrandContents
     * @name ProductBrandContentsDeleteDelete
     * @request DELETE:/api/ProductBrandContents/Delete/{id}
     * @secure
     */
    productBrandContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductBrandContent, any>({
        path: `/api/ProductBrandContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrands
     * @name ProductBrandsGetAllDynamicList
     * @request GET:/api/ProductBrands/GetAllDynamic
     * @secure
     */
    productBrandsGetAllDynamicList: (
      query: IProductBrandsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductBrandDynamicResponse, any>({
        path: `/api/ProductBrands/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrands
     * @name ProductBrandsCreateCreate
     * @request POST:/api/ProductBrands/Create
     * @secure
     */
    productBrandsCreateCreate: (data: IProductBrand, params: RequestParams = {}) =>
      this.request<IProductBrand, any>({
        path: `/api/ProductBrands/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrands
     * @name ProductBrandsPatchPartialUpdate
     * @request PATCH:/api/ProductBrands/Patch/{id}
     * @secure
     */
    productBrandsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ProductBrands/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrands
     * @name ProductBrandsDeleteDelete
     * @request DELETE:/api/ProductBrands/Delete/{id}
     * @secure
     */
    productBrandsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductBrand, any>({
        path: `/api/ProductBrands/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCases
     * @name ProductCasesGetAllDynamicList
     * @request GET:/api/ProductCases/GetAllDynamic
     * @secure
     */
    productCasesGetAllDynamicList: (
      query: IProductCasesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductCaseDynamicResponse, any>({
        path: `/api/ProductCases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCases
     * @name ProductCasesCreateCreate
     * @request POST:/api/ProductCases/Create
     * @secure
     */
    productCasesCreateCreate: (data: IProductCase, params: RequestParams = {}) =>
      this.request<IProductCase, any>({
        path: `/api/ProductCases/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCases
     * @name ProductCasesPatchPartialUpdate
     * @request PATCH:/api/ProductCases/Patch/{id}
     * @secure
     */
    productCasesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ProductCases/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCases
     * @name ProductCasesDeleteDelete
     * @request DELETE:/api/ProductCases/Delete/{id}
     * @secure
     */
    productCasesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductCase, any>({
        path: `/api/ProductCases/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCases
     * @name ProductCasesCreateBulkCreate
     * @request POST:/api/ProductCases/CreateBulk
     * @secure
     */
    productCasesCreateBulkCreate: (data: IRelationshipBulk, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ProductCases/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContents
     * @name ProductContentsGetAllDynamicList
     * @request GET:/api/ProductContents/GetAllDynamic
     * @secure
     */
    productContentsGetAllDynamicList: (
      query: IProductContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductContentDynamicResponse, any>({
        path: `/api/ProductContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContents
     * @name ProductContentsCreateCreate
     * @request POST:/api/ProductContents/Create
     * @secure
     */
    productContentsCreateCreate: (data: IProductContent, params: RequestParams = {}) =>
      this.request<IProductContent, any>({
        path: `/api/ProductContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContents
     * @name ProductContentsPatchPartialUpdate
     * @request PATCH:/api/ProductContents/Patch/{id}
     * @secure
     */
    productContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContents
     * @name ProductContentsDeleteDelete
     * @request DELETE:/api/ProductContents/Delete/{id}
     * @secure
     */
    productContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductContent, any>({
        path: `/api/ProductContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDietaryPreferences
     * @name ProductDietaryPreferencesGetAllDynamicList
     * @request GET:/api/ProductDietaryPreferences/GetAllDynamic
     * @secure
     */
    productDietaryPreferencesGetAllDynamicList: (
      query: IProductDietaryPreferencesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductDietaryPreferenceDynamicResponse, any>({
        path: `/api/ProductDietaryPreferences/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDietaryPreferences
     * @name ProductDietaryPreferencesCreateCreate
     * @request POST:/api/ProductDietaryPreferences/Create
     * @secure
     */
    productDietaryPreferencesCreateCreate: (
      data: IProductDietaryPreference,
      params: RequestParams = {},
    ) =>
      this.request<IProductDietaryPreference, any>({
        path: `/api/ProductDietaryPreferences/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDietaryPreferences
     * @name ProductDietaryPreferencesPatchPartialUpdate
     * @request PATCH:/api/ProductDietaryPreferences/Patch/{id}
     * @secure
     */
    productDietaryPreferencesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductDietaryPreferences/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDietaryPreferences
     * @name ProductDietaryPreferencesDeleteDelete
     * @request DELETE:/api/ProductDietaryPreferences/Delete/{id}
     * @secure
     */
    productDietaryPreferencesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductDietaryPreference, any>({
        path: `/api/ProductDietaryPreferences/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDietaryPreferences
     * @name ProductDietaryPreferencesCreateBulkCreate
     * @request POST:/api/ProductDietaryPreferences/CreateBulk
     * @secure
     */
    productDietaryPreferencesCreateBulkCreate: (
      data: IRelationshipBulk,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductDietaryPreferences/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductIngredients
     * @name ProductIngredientsGetAllDynamicList
     * @request GET:/api/ProductIngredients/GetAllDynamic
     * @secure
     */
    productIngredientsGetAllDynamicList: (
      query: IProductIngredientsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductIngredientDynamicResponse, any>({
        path: `/api/ProductIngredients/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductIngredients
     * @name ProductIngredientsCreateCreate
     * @request POST:/api/ProductIngredients/Create
     * @secure
     */
    productIngredientsCreateCreate: (data: IProductIngredient, params: RequestParams = {}) =>
      this.request<IProductIngredient, any>({
        path: `/api/ProductIngredients/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductIngredients
     * @name ProductIngredientsPatchPartialUpdate
     * @request PATCH:/api/ProductIngredients/Patch/{id}
     * @secure
     */
    productIngredientsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductIngredients/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductIngredients
     * @name ProductIngredientsDeleteDelete
     * @request DELETE:/api/ProductIngredients/Delete/{id}
     * @secure
     */
    productIngredientsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductIngredient, any>({
        path: `/api/ProductIngredients/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductLeaflets
     * @name ProductLeafletsGetAllDynamicList
     * @request GET:/api/ProductLeaflets/GetAllDynamic
     * @secure
     */
    productLeafletsGetAllDynamicList: (
      query: IProductLeafletsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductLeafletDynamicResponse, any>({
        path: `/api/ProductLeaflets/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductLeaflets
     * @name ProductLeafletsCreateCreate
     * @request POST:/api/ProductLeaflets/Create
     * @secure
     */
    productLeafletsCreateCreate: (data: IProductLeaflet, params: RequestParams = {}) =>
      this.request<IProductLeaflet, any>({
        path: `/api/ProductLeaflets/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductLeaflets
     * @name ProductLeafletsPatchPartialUpdate
     * @request PATCH:/api/ProductLeaflets/Patch/{id}
     * @secure
     */
    productLeafletsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductLeaflets/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductLeaflets
     * @name ProductLeafletsDeleteDelete
     * @request DELETE:/api/ProductLeaflets/Delete/{id}
     * @secure
     */
    productLeafletsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductLeaflet, any>({
        path: `/api/ProductLeaflets/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedicalConditions
     * @name ProductMedicalConditionsGetAllDynamicList
     * @request GET:/api/ProductMedicalConditions/GetAllDynamic
     * @secure
     */
    productMedicalConditionsGetAllDynamicList: (
      query: IProductMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductMedicalConditionDynamicResponse, any>({
        path: `/api/ProductMedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedicalConditions
     * @name ProductMedicalConditionsCreateCreate
     * @request POST:/api/ProductMedicalConditions/Create
     * @secure
     */
    productMedicalConditionsCreateCreate: (
      data: IProductMedicalCondition,
      params: RequestParams = {},
    ) =>
      this.request<IProductMedicalCondition, any>({
        path: `/api/ProductMedicalConditions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedicalConditions
     * @name ProductMedicalConditionsPatchPartialUpdate
     * @request PATCH:/api/ProductMedicalConditions/Patch/{id}
     * @secure
     */
    productMedicalConditionsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductMedicalConditions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedicalConditions
     * @name ProductMedicalConditionsDeleteDelete
     * @request DELETE:/api/ProductMedicalConditions/Delete/{id}
     * @secure
     */
    productMedicalConditionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductMedicalCondition, any>({
        path: `/api/ProductMedicalConditions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedicalConditions
     * @name ProductMedicalConditionsCreateBulkCreate
     * @request POST:/api/ProductMedicalConditions/CreateBulk
     * @secure
     */
    productMedicalConditionsCreateBulkCreate: (
      data: IRelationshipBulk,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductMedicalConditions/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedications
     * @name ProductMedicationsGetAllDynamicList
     * @request GET:/api/ProductMedications/GetAllDynamic
     * @secure
     */
    productMedicationsGetAllDynamicList: (
      query: IProductMedicationsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductMedicationDynamicResponse, any>({
        path: `/api/ProductMedications/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedications
     * @name ProductMedicationsCreateCreate
     * @request POST:/api/ProductMedications/Create
     * @secure
     */
    productMedicationsCreateCreate: (data: IProductMedication, params: RequestParams = {}) =>
      this.request<IProductMedication, any>({
        path: `/api/ProductMedications/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedications
     * @name ProductMedicationsPatchPartialUpdate
     * @request PATCH:/api/ProductMedications/Patch/{id}
     * @secure
     */
    productMedicationsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductMedications/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedications
     * @name ProductMedicationsDeleteDelete
     * @request DELETE:/api/ProductMedications/Delete/{id}
     * @secure
     */
    productMedicationsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductMedication, any>({
        path: `/api/ProductMedications/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedications
     * @name ProductMedicationsCreateBulkCreate
     * @request POST:/api/ProductMedications/CreateBulk
     * @secure
     */
    productMedicationsCreateBulkCreate: (data: IRelationshipBulk, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ProductMedications/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetAllDynamicList
     * @request GET:/api/Products/GetAllDynamic
     * @secure
     */
    productsGetAllDynamicList: (
      query: IProductsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductDynamicResponse, any>({
        path: `/api/Products/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsCreateCreate
     * @request POST:/api/Products/Create
     * @secure
     */
    productsCreateCreate: (data: IProduct, params: RequestParams = {}) =>
      this.request<IProduct, any>({
        path: `/api/Products/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsPatchPartialUpdate
     * @request PATCH:/api/Products/Patch/{id}
     * @secure
     */
    productsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Products/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsDeleteDelete
     * @request DELETE:/api/Products/Delete/{id}
     * @secure
     */
    productsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProduct, any>({
        path: `/api/Products/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSensitivities
     * @name ProductSensitivitiesGetAllDynamicList
     * @request GET:/api/ProductSensitivities/GetAllDynamic
     * @secure
     */
    productSensitivitiesGetAllDynamicList: (
      query: IProductSensitivitiesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductSensitivityDynamicResponse, any>({
        path: `/api/ProductSensitivities/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSensitivities
     * @name ProductSensitivitiesCreateCreate
     * @request POST:/api/ProductSensitivities/Create
     * @secure
     */
    productSensitivitiesCreateCreate: (data: IProductSensitivity, params: RequestParams = {}) =>
      this.request<IProductSensitivity, any>({
        path: `/api/ProductSensitivities/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSensitivities
     * @name ProductSensitivitiesPatchPartialUpdate
     * @request PATCH:/api/ProductSensitivities/Patch/{id}
     * @secure
     */
    productSensitivitiesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductSensitivities/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSensitivities
     * @name ProductSensitivitiesDeleteDelete
     * @request DELETE:/api/ProductSensitivities/Delete/{id}
     * @secure
     */
    productSensitivitiesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductSensitivity, any>({
        path: `/api/ProductSensitivities/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSensitivities
     * @name ProductSensitivitiesCreateBulkCreate
     * @request POST:/api/ProductSensitivities/CreateBulk
     * @secure
     */
    productSensitivitiesCreateBulkCreate: (data: IRelationshipBulk, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ProductSensitivities/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesGetAllDynamicList
     * @request GET:/api/ProductsTailorFitCases/GetAllDynamic
     * @secure
     */
    productsTailorFitCasesGetAllDynamicList: (
      query: IProductsTailorFitCasesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitCaseDynamicResponse, any>({
        path: `/api/ProductsTailorFitCases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesCreateCreate
     * @request POST:/api/ProductsTailorFitCases/Create
     * @secure
     */
    productsTailorFitCasesCreateCreate: (
      data: IProductsTailorFitCase,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitCase, any>({
        path: `/api/ProductsTailorFitCases/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesPatchPartialUpdate
     * @request PATCH:/api/ProductsTailorFitCases/Patch/{id}
     * @secure
     */
    productsTailorFitCasesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitCases/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesDeleteDelete
     * @request DELETE:/api/ProductsTailorFitCases/Delete/{id}
     * @secure
     */
    productsTailorFitCasesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductsTailorFitCase, any>({
        path: `/api/ProductsTailorFitCases/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesGetAllDynamicList
     * @request GET:/api/ProductsTailorFitThemes/GetAllDynamic
     * @secure
     */
    productsTailorFitThemesGetAllDynamicList: (
      query: IProductsTailorFitThemesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitThemeDynamicResponse, any>({
        path: `/api/ProductsTailorFitThemes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesCreateCreate
     * @request POST:/api/ProductsTailorFitThemes/Create
     * @secure
     */
    productsTailorFitThemesCreateCreate: (
      data: IProductsTailorFitTheme,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitTheme, any>({
        path: `/api/ProductsTailorFitThemes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesPatchPartialUpdate
     * @request PATCH:/api/ProductsTailorFitThemes/Patch/{id}
     * @secure
     */
    productsTailorFitThemesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitThemes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesDeleteDelete
     * @request DELETE:/api/ProductsTailorFitThemes/Delete/{id}
     * @secure
     */
    productsTailorFitThemesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductsTailorFitTheme, any>({
        path: `/api/ProductsTailorFitThemes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesDeleteDelete
     * @request DELETE:/api/ProductsTailorFitUserProfiles/Delete/{appUserID}
     * @secure
     */
    productsTailorFitUserProfilesDeleteDelete: (appUserId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/ProductsTailorFitUserProfiles/Delete/${appUserId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesGetAllDynamicList
     * @request GET:/api/ProductsTailorFitUserProfiles/GetAllDynamic
     * @secure
     */
    productsTailorFitUserProfilesGetAllDynamicList: (
      query: IProductsTailorFitUserProfilesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/ProductsTailorFitUserProfiles/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesCreateCreate
     * @request POST:/api/ProductsTailorFitUserProfiles/Create
     * @secure
     */
    productsTailorFitUserProfilesCreateCreate: (
      data: IProductsTailorFitUserProfile,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/ProductsTailorFitUserProfiles/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesPatchPartialUpdate
     * @request PATCH:/api/ProductsTailorFitUserProfiles/Patch/{appUserID}
     * @secure
     */
    productsTailorFitUserProfilesPatchPartialUpdate: (
      appUserId: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/ProductsTailorFitUserProfiles/Patch/${appUserId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSymptoms
     * @name ProductSymptomsGetAllDynamicList
     * @request GET:/api/ProductSymptoms/GetAllDynamic
     * @secure
     */
    productSymptomsGetAllDynamicList: (
      query: IProductSymptomsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductSymptomDynamicResponse, any>({
        path: `/api/ProductSymptoms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSymptoms
     * @name ProductSymptomsCreateCreate
     * @request POST:/api/ProductSymptoms/Create
     * @secure
     */
    productSymptomsCreateCreate: (data: IProductSymptom, params: RequestParams = {}) =>
      this.request<IProductSymptom, any>({
        path: `/api/ProductSymptoms/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSymptoms
     * @name ProductSymptomsPatchPartialUpdate
     * @request PATCH:/api/ProductSymptoms/Patch/{id}
     * @secure
     */
    productSymptomsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductSymptoms/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSymptoms
     * @name ProductSymptomsDeleteDelete
     * @request DELETE:/api/ProductSymptoms/Delete/{id}
     * @secure
     */
    productSymptomsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductSymptom, any>({
        path: `/api/ProductSymptoms/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagContents
     * @name RedFlagContentsGetAllDynamicList
     * @request GET:/api/RedFlagContents/GetAllDynamic
     * @secure
     */
    redFlagContentsGetAllDynamicList: (
      query: IRedFlagContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagContentDynamicResponse, any>({
        path: `/api/RedFlagContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagContents
     * @name RedFlagContentsCreateCreate
     * @request POST:/api/RedFlagContents/Create
     * @secure
     */
    redFlagContentsCreateCreate: (data: IRedFlagContent, params: RequestParams = {}) =>
      this.request<IRedFlagContent, any>({
        path: `/api/RedFlagContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagContents
     * @name RedFlagContentsPatchPartialUpdate
     * @request PATCH:/api/RedFlagContents/Patch/{id}
     * @secure
     */
    redFlagContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/RedFlagContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagContents
     * @name RedFlagContentsDeleteDelete
     * @request DELETE:/api/RedFlagContents/Delete/{id}
     * @secure
     */
    redFlagContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedFlagContent, any>({
        path: `/api/RedFlagContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptionContents
     * @name RedFlagOptionContentsGetAllDynamicList
     * @request GET:/api/RedFlagOptionContents/GetAllDynamic
     * @secure
     */
    redFlagOptionContentsGetAllDynamicList: (
      query: IRedFlagOptionContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagOptionContentDynamicResponse, any>({
        path: `/api/RedFlagOptionContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptionContents
     * @name RedFlagOptionContentsCreateCreate
     * @request POST:/api/RedFlagOptionContents/Create
     * @secure
     */
    redFlagOptionContentsCreateCreate: (data: IRedFlagOptionContent, params: RequestParams = {}) =>
      this.request<IRedFlagOptionContent, any>({
        path: `/api/RedFlagOptionContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptionContents
     * @name RedFlagOptionContentsPatchPartialUpdate
     * @request PATCH:/api/RedFlagOptionContents/Patch/{id}
     * @secure
     */
    redFlagOptionContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/RedFlagOptionContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptionContents
     * @name RedFlagOptionContentsDeleteDelete
     * @request DELETE:/api/RedFlagOptionContents/Delete/{id}
     * @secure
     */
    redFlagOptionContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedFlagOptionContent, any>({
        path: `/api/RedFlagOptionContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptions
     * @name RedFlagOptionsGetAllDynamicList
     * @request GET:/api/RedFlagOptions/GetAllDynamic
     * @secure
     */
    redFlagOptionsGetAllDynamicList: (
      query: IRedFlagOptionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagOptionDynamicResponse, any>({
        path: `/api/RedFlagOptions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptions
     * @name RedFlagOptionsCreateCreate
     * @request POST:/api/RedFlagOptions/Create
     * @secure
     */
    redFlagOptionsCreateCreate: (data: IRedFlagOption, params: RequestParams = {}) =>
      this.request<IRedFlagOption, any>({
        path: `/api/RedFlagOptions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptions
     * @name RedFlagOptionsPatchPartialUpdate
     * @request PATCH:/api/RedFlagOptions/Patch/{id}
     * @secure
     */
    redFlagOptionsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/RedFlagOptions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptions
     * @name RedFlagOptionsDeleteDelete
     * @request DELETE:/api/RedFlagOptions/Delete/{id}
     * @secure
     */
    redFlagOptionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedFlagOption, any>({
        path: `/api/RedFlagOptions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPageContents
     * @name RedFlagPageContentsGetAllDynamicList
     * @request GET:/api/RedFlagPageContents/GetAllDynamic
     * @secure
     */
    redFlagPageContentsGetAllDynamicList: (
      query: IRedFlagPageContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagPageContentDynamicResponse, any>({
        path: `/api/RedFlagPageContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPageContents
     * @name RedFlagPageContentsCreateCreate
     * @request POST:/api/RedFlagPageContents/Create
     * @secure
     */
    redFlagPageContentsCreateCreate: (data: IRedFlagPageContent, params: RequestParams = {}) =>
      this.request<IRedFlagPageContent, any>({
        path: `/api/RedFlagPageContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPageContents
     * @name RedFlagPageContentsPatchPartialUpdate
     * @request PATCH:/api/RedFlagPageContents/Patch/{id}
     * @secure
     */
    redFlagPageContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/RedFlagPageContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPageContents
     * @name RedFlagPageContentsDeleteDelete
     * @request DELETE:/api/RedFlagPageContents/Delete/{id}
     * @secure
     */
    redFlagPageContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedFlagPageContent, any>({
        path: `/api/RedFlagPageContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPages
     * @name RedFlagPagesGetAllDynamicList
     * @request GET:/api/RedFlagPages/GetAllDynamic
     * @secure
     */
    redFlagPagesGetAllDynamicList: (
      query: IRedFlagPagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagPageDynamicResponse, any>({
        path: `/api/RedFlagPages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPages
     * @name RedFlagPagesCreateCreate
     * @request POST:/api/RedFlagPages/Create
     * @secure
     */
    redFlagPagesCreateCreate: (data: IRedFlagPage, params: RequestParams = {}) =>
      this.request<IRedFlagPage, any>({
        path: `/api/RedFlagPages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPages
     * @name RedFlagPagesPatchPartialUpdate
     * @request PATCH:/api/RedFlagPages/Patch/{id}
     * @secure
     */
    redFlagPagesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/RedFlagPages/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPages
     * @name RedFlagPagesDeleteDelete
     * @request DELETE:/api/RedFlagPages/Delete/{id}
     * @secure
     */
    redFlagPagesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedFlagPage, any>({
        path: `/api/RedFlagPages/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlags
     * @name RedFlagsGetAllDynamicList
     * @request GET:/api/RedFlags/GetAllDynamic
     * @secure
     */
    redFlagsGetAllDynamicList: (
      query: IRedFlagsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagDynamicResponse, any>({
        path: `/api/RedFlags/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlags
     * @name RedFlagsCreateCreate
     * @request POST:/api/RedFlags/Create
     * @secure
     */
    redFlagsCreateCreate: (data: IRedFlag, params: RequestParams = {}) =>
      this.request<IRedFlag, any>({
        path: `/api/RedFlags/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlags
     * @name RedFlagsPatchPartialUpdate
     * @request PATCH:/api/RedFlags/Patch/{id}
     * @secure
     */
    redFlagsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/RedFlags/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlags
     * @name RedFlagsDeleteDelete
     * @request DELETE:/api/RedFlags/Delete/{id}
     * @secure
     */
    redFlagsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedFlag, any>({
        path: `/api/RedFlags/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagTypes
     * @name RedFlagTypesGetAllDynamicList
     * @request GET:/api/RedFlagTypes/GetAllDynamic
     * @secure
     */
    redFlagTypesGetAllDynamicList: (
      query: IRedFlagTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagTypeDynamicResponse, any>({
        path: `/api/RedFlagTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagTypes
     * @name RedFlagTypesCreateCreate
     * @request POST:/api/RedFlagTypes/Create
     * @secure
     */
    redFlagTypesCreateCreate: (data: IRedFlagType, params: RequestParams = {}) =>
      this.request<IRedFlagType, any>({
        path: `/api/RedFlagTypes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagTypes
     * @name RedFlagTypesPatchPartialUpdate
     * @request PATCH:/api/RedFlagTypes/Patch/{id}
     * @secure
     */
    redFlagTypesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/RedFlagTypes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagTypes
     * @name RedFlagTypesDeleteDelete
     * @request DELETE:/api/RedFlagTypes/Delete/{id}
     * @secure
     */
    redFlagTypesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedFlagType, any>({
        path: `/api/RedFlagTypes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsGetAllDynamicList
     * @request GET:/api/RedisParams/GetAllDynamic
     * @secure
     */
    redisParamsGetAllDynamicList: (
      query: IRedisParamsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedisParamDynamicResponse, any>({
        path: `/api/RedisParams/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsCreateCreate
     * @request POST:/api/RedisParams/Create
     * @secure
     */
    redisParamsCreateCreate: (data: IRedisParam, params: RequestParams = {}) =>
      this.request<IRedisParam, any>({
        path: `/api/RedisParams/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsPatchPartialUpdate
     * @request PATCH:/api/RedisParams/Patch/{id}
     * @secure
     */
    redisParamsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/RedisParams/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsDeleteDelete
     * @request DELETE:/api/RedisParams/Delete/{id}
     * @secure
     */
    redisParamsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedisParam, any>({
        path: `/api/RedisParams/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensitivities
     * @name SensitivitiesGetAllDynamicList
     * @request GET:/api/Sensitivities/GetAllDynamic
     * @secure
     */
    sensitivitiesGetAllDynamicList: (
      query: ISensitivitiesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISensitivityDynamicResponse, any>({
        path: `/api/Sensitivities/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensitivities
     * @name SensitivitiesCreateCreate
     * @request POST:/api/Sensitivities/Create
     * @secure
     */
    sensitivitiesCreateCreate: (data: ISensitivity, params: RequestParams = {}) =>
      this.request<ISensitivity, any>({
        path: `/api/Sensitivities/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensitivities
     * @name SensitivitiesPatchPartialUpdate
     * @request PATCH:/api/Sensitivities/Patch/{id}
     * @secure
     */
    sensitivitiesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Sensitivities/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensitivities
     * @name SensitivitiesDeleteDelete
     * @request DELETE:/api/Sensitivities/Delete/{id}
     * @secure
     */
    sensitivitiesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ISensitivity, any>({
        path: `/api/Sensitivities/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SensitivityContents
     * @name SensitivityContentsGetAllDynamicList
     * @request GET:/api/SensitivityContents/GetAllDynamic
     * @secure
     */
    sensitivityContentsGetAllDynamicList: (
      query: ISensitivityContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISensitivityContentDynamicResponse, any>({
        path: `/api/SensitivityContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SensitivityContents
     * @name SensitivityContentsCreateCreate
     * @request POST:/api/SensitivityContents/Create
     * @secure
     */
    sensitivityContentsCreateCreate: (data: ISensitivityContent, params: RequestParams = {}) =>
      this.request<ISensitivityContent, any>({
        path: `/api/SensitivityContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SensitivityContents
     * @name SensitivityContentsPatchPartialUpdate
     * @request PATCH:/api/SensitivityContents/Patch/{id}
     * @secure
     */
    sensitivityContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/SensitivityContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SensitivityContents
     * @name SensitivityContentsDeleteDelete
     * @request DELETE:/api/SensitivityContents/Delete/{id}
     * @secure
     */
    sensitivityContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ISensitivityContent, any>({
        path: `/api/SensitivityContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SymptomContents
     * @name SymptomContentsGetAllDynamicList
     * @request GET:/api/SymptomContents/GetAllDynamic
     * @secure
     */
    symptomContentsGetAllDynamicList: (
      query: ISymptomContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISymptomContentDynamicResponse, any>({
        path: `/api/SymptomContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SymptomContents
     * @name SymptomContentsCreateCreate
     * @request POST:/api/SymptomContents/Create
     * @secure
     */
    symptomContentsCreateCreate: (data: ISymptomContent, params: RequestParams = {}) =>
      this.request<ISymptomContent, any>({
        path: `/api/SymptomContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SymptomContents
     * @name SymptomContentsPatchPartialUpdate
     * @request PATCH:/api/SymptomContents/Patch/{id}
     * @secure
     */
    symptomContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/SymptomContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SymptomContents
     * @name SymptomContentsDeleteDelete
     * @request DELETE:/api/SymptomContents/Delete/{id}
     * @secure
     */
    symptomContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ISymptomContent, any>({
        path: `/api/SymptomContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Symptoms
     * @name SymptomsGetAllDynamicList
     * @request GET:/api/Symptoms/GetAllDynamic
     * @secure
     */
    symptomsGetAllDynamicList: (
      query: ISymptomsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISymptomDynamicResponse, any>({
        path: `/api/Symptoms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Symptoms
     * @name SymptomsCreateCreate
     * @request POST:/api/Symptoms/Create
     * @secure
     */
    symptomsCreateCreate: (data: ISymptom, params: RequestParams = {}) =>
      this.request<ISymptom, any>({
        path: `/api/Symptoms/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Symptoms
     * @name SymptomsPatchPartialUpdate
     * @request PATCH:/api/Symptoms/Patch/{id}
     * @secure
     */
    symptomsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Symptoms/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Symptoms
     * @name SymptomsDeleteDelete
     * @request DELETE:/api/Symptoms/Delete/{id}
     * @secure
     */
    symptomsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ISymptom, any>({
        path: `/api/Symptoms/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsGetAllDynamicList
     * @request GET:/api/Tenants/GetAllDynamic
     * @secure
     */
    tenantsGetAllDynamicList: (
      query: ITenantsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ITenantDynamicResponse, any>({
        path: `/api/Tenants/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsCreateCreate
     * @request POST:/api/Tenants/Create
     * @secure
     */
    tenantsCreateCreate: (data: ITenant, params: RequestParams = {}) =>
      this.request<ITenant, any>({
        path: `/api/Tenants/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsPatchPartialUpdate
     * @request PATCH:/api/Tenants/Patch/{id}
     * @secure
     */
    tenantsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Tenants/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsDeleteDelete
     * @request DELETE:/api/Tenants/Delete/{id}
     * @secure
     */
    tenantsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ITenant, any>({
        path: `/api/Tenants/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfile
     * @name UserCrmProfileGetAllDynamicList
     * @request GET:/api/UserCrmProfile/GetAllDynamic
     * @secure
     */
    userCrmProfileGetAllDynamicList: (
      query: IUserCrmProfileGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserCrmProfileDynamicResponse, any>({
        path: `/api/UserCrmProfile/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfile
     * @name UserCrmProfileCreateCreate
     * @request POST:/api/UserCrmProfile/Create
     * @secure
     */
    userCrmProfileCreateCreate: (data: IUserCrmProfile, params: RequestParams = {}) =>
      this.request<IUserCrmProfile, any>({
        path: `/api/UserCrmProfile/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfile
     * @name UserCrmProfilePatchPartialUpdate
     * @request PATCH:/api/UserCrmProfile/Patch/{appUserID}
     * @secure
     */
    userCrmProfilePatchPartialUpdate: (
      appUserId: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/UserCrmProfile/Patch/${appUserId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfile
     * @name UserCrmProfileDeleteDelete
     * @request DELETE:/api/UserCrmProfile/Delete/{appUserID}
     * @secure
     */
    userCrmProfileDeleteDelete: (appUserId: string, params: RequestParams = {}) =>
      this.request<IUserCrmProfile, any>({
        path: `/api/UserCrmProfile/Delete/${appUserId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfilePermissions
     * @name UserCrmProfilePermissionsGetAllDynamicList
     * @request GET:/api/UserCrmProfilePermissions/GetAllDynamic
     * @secure
     */
    userCrmProfilePermissionsGetAllDynamicList: (
      query: IUserCrmProfilePermissionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserCrmProfilePermissionDynamicResponse, any>({
        path: `/api/UserCrmProfilePermissions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfilePermissions
     * @name UserCrmProfilePermissionsCreateCreate
     * @request POST:/api/UserCrmProfilePermissions/Create
     * @secure
     */
    userCrmProfilePermissionsCreateCreate: (
      data: IUserCrmProfilePermission,
      params: RequestParams = {},
    ) =>
      this.request<IUserCrmProfilePermission, any>({
        path: `/api/UserCrmProfilePermissions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfilePermissions
     * @name UserCrmProfilePermissionsPatchPartialUpdate
     * @request PATCH:/api/UserCrmProfilePermissions/Patch/{id}
     * @secure
     */
    userCrmProfilePermissionsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/UserCrmProfilePermissions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserCrmProfilePermissions
     * @name UserCrmProfilePermissionsDeleteDelete
     * @request DELETE:/api/UserCrmProfilePermissions/Delete/{id}
     * @secure
     */
    userCrmProfilePermissionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserCrmProfilePermission, any>({
        path: `/api/UserCrmProfilePermissions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenantProfiles
     * @name UserTenantProfilesGetAllDynamicList
     * @request GET:/api/UserTenantProfiles/GetAllDynamic
     * @secure
     */
    userTenantProfilesGetAllDynamicList: (
      query: IUserTenantProfilesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserTenantProfileDynamicResponse, any>({
        path: `/api/UserTenantProfiles/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenantProfiles
     * @name UserTenantProfilesCreateCreate
     * @request POST:/api/UserTenantProfiles/Create
     * @secure
     */
    userTenantProfilesCreateCreate: (data: IUserTenantProfile, params: RequestParams = {}) =>
      this.request<IUserTenantProfile, any>({
        path: `/api/UserTenantProfiles/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenantProfiles
     * @name UserTenantProfilesPatchPartialUpdate
     * @request PATCH:/api/UserTenantProfiles/Patch/{appUserID}
     * @secure
     */
    userTenantProfilesPatchPartialUpdate: (
      appUserId: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/UserTenantProfiles/Patch/${appUserId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenantProfiles
     * @name UserTenantProfilesDeleteDelete
     * @request DELETE:/api/UserTenantProfiles/Delete/{appUserID}
     * @secure
     */
    userTenantProfilesDeleteDelete: (appUserId: string, params: RequestParams = {}) =>
      this.request<IUserTenantProfile, any>({
        path: `/api/UserTenantProfiles/Delete/${appUserId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenants
     * @name UserTenantsGetAllDynamicList
     * @request GET:/api/UserTenants/GetAllDynamic
     * @secure
     */
    userTenantsGetAllDynamicList: (
      query: IUserTenantsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserTenantDynamicResponse, any>({
        path: `/api/UserTenants/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenants
     * @name UserTenantsCreateCreate
     * @request POST:/api/UserTenants/Create
     * @secure
     */
    userTenantsCreateCreate: (data: IUserTenant, params: RequestParams = {}) =>
      this.request<IUserTenant, any>({
        path: `/api/UserTenants/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenants
     * @name UserTenantsPatchPartialUpdate
     * @request PATCH:/api/UserTenants/Patch/{id}
     * @secure
     */
    userTenantsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/UserTenants/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenants
     * @name UserTenantsDeleteDelete
     * @request DELETE:/api/UserTenants/Delete/{id}
     * @secure
     */
    userTenantsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserTenant, any>({
        path: `/api/UserTenants/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserTenants
     * @name UserTenantsCreateBulkCreate
     * @request POST:/api/UserTenants/CreateBulk
     * @secure
     */
    userTenantsCreateBulkCreate: (data: IRelationshipBulk, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/UserTenants/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
